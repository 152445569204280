import ComponentProvider from "@/components/editor/ComponentProvider";
import {
    CanvasMemories,
    CanvasState,
    ComboType,
    EditorState,
    Element,
    ElementData,
    ElementType,
    Group,
    GroupData,
    PriceType,
    ProductState,
    RemoveElementPayload,
    Sections,
    SelectShapePayload,
    SelectedElement,
    ShapeInfo,
    TemplateType,
    UpdateElementPayload,
    UpdateElementRefPayload,
    UpdateOrientationPayload,
    ViewMode
} from "@/types/canvas";
import { Orientation } from "@mui/material";
import Konva from "konva";
import { RefObject } from "react";

export interface ICanvasDispatch {
    stateDispatch: React.Dispatch<IStateActions>
}

export type IStateActions =
    | { type: "setTemplateId", templateId: string | null }
    | { type: "setTemplateName", templateName: string | null }
    | { type: "setTemplateType", templateType: TemplateType }
    | { type: "setCampaignId", campaignId: string | null }
    | { type: "setCampaignName", campaignName: string | null }
    | { type: "setPriceType", priceType: PriceType }
    | { type: "setEconomizePriceType", economizePriceType: PriceType | null }
    | { type: "changeViewMode", viewMode: ViewMode }
    | { type: "createProduct" }
    | { type: "removeProduct", id: string | null }
    | { type: "selectProduct", id: string | null }
    | { type: "attachElementToProduct", id: string | null, productId: string | null }
    | { type: "addElement", attributes: Element }
    | { type: "addElements", attributeList: Element[] }
    | { type: "duplicateElement", id: string | null, seedAttrs?: Partial<Element> }
    | { type: "addGroup", id: string, elements: Element[] }
    | { type: "addElementToGroup", groupId: string, elementId: string }
    | { type: "removeElement", id: RemoveElementPayload }
    | { type: "selectShape", payload: SelectShapePayload }
    | { type: "updateElement", payload: UpdateElementPayload }
    | { type: "updateElementRef", payload: UpdateElementRefPayload }
    | { type: "updateGroup", id: string, newGroupData: Group }
    | { type: "updateOrientation", orientation: UpdateOrientationPayload }
    | { type: "useBackgroundRemover", value: boolean }
    | { type: "unselect" }
    | { type: "injectElements", elements: ElementData[], products?: ProductState }
    | { type: "resetCanvas", newValues?: Partial<CanvasState> }
    | { type: "loadStateData", data: CanvasState, elements: ElementData[], groups: GroupData[] }
    | { type: "updateSection", section: Sections }
    | { type: "setStageRef", stage: RefObject<Konva.Stage> | null }
    | { type: "replaceElement", id: string, attributes: Element }
    | { type: "setIsZoomEnabled", isOn: boolean }
    | { type: "ungroup", id: RemoveElementPayload }
    | { type: "setHovering", element: string | null }
    | { type: "setComboType", comboType: ComboType }
    | { type: "setCampaignComboType", campaignComboType: ComboType[] }

export function create_background(): ElementData {
    return { ref: null, attributes: ComponentProvider.background() as Element, type: "element", zIndex: 1 };
}

export function buildShapeInfo(selectedID: string | null, selectedType: ElementType | null): ShapeInfo {
    return {
        id: selectedID,
        exists: selectedID !== null,
        isBackground: selectedType === 'background',
        isCircle: selectedType === 'circle',
        isImage: selectedType === 'image',
        isRectangle: selectedType === 'rectangle',
        isText: selectedType === 'text',
        isGroup: !!((selectedID && !selectedType) && (selectedID.includes('group_selection')))
    }
}

const DEFAULT_ORIENTATION: Orientation = "vertical";

export const __BACKGROUND__: string = "background";

export const initialShapeInfo: ShapeInfo = {
    id: null,
    exists: false,
    isBackground: false,
    isCircle: false,
    isImage: false,
    isRectangle: false,
    isText: false,
    isGroup: false
};

export const initialSelection: SelectedElement = { id: "background", type: "background", exists: true, info: buildShapeInfo('background', 'background') };

export const initialProductsState: ProductState = {
    ids: [],
    selected: null
}

export const initialEditorState: EditorState = {
    section: 'products',
    orientation: DEFAULT_ORIENTATION,
    isZoomEnabled: false,
    viewMode: "view",
    useBackgroundRemover: false
}

export const initialMemories: CanvasMemories = {
    stageRef: null,
    editor: initialEditorState,
    elements: [create_background()],
    groups: [],
    current: initialSelection,
    shapeInfo: initialShapeInfo,
    products: initialProductsState
};

export const initialState: CanvasState = {
    templateId: null,
    templateName: null,
    templateType: 'cartaz',
    campaignId: null,
    campaignName: null,
    stageRef: null,
    editor: initialEditorState,
    elements: [create_background()],
    groups: [],
    current: initialSelection,
    shapeInfo: initialShapeInfo,
    products: initialProductsState,
    history: [initialMemories],
    hovering: null,
    _forcedUpdate: 0,
    priceType: 'simple',
    economizePriceType: null,
    comboType: null,
    campaignComboType: [],
};