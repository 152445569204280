import { createContext, useContext } from "react";
import { ReactChildren } from "@/types";
import { ApXUNType, LeveXPagueYType, XOffType, LevePORType } from "../editor/DynamicPromoContext";
import { useCanvas } from "../editor/CanvasContext";
import { ProductListItem } from "@/types/products";
import { PriceType } from "@/types/canvas";
import PriceHelper from '../../helpers/PriceHelper';

interface PricingProviderInterface {
    children: ReactChildren
}

type PriceTypeBatch = "simple" | "club" | "offer" | "extra" | "none" | undefined;

interface PricingContextData {
    handlePriceByTextType: (
        product: ProductListItem,
        ap_x_UN: ApXUNType,
        leveX_pagueY: LeveXPagueYType,
        leve_Por: LevePORType,
        xOff: XOffType,
        calculate?: boolean
    ) => string[] | undefined | Error;
    priceTarget: (priceType: PriceType, product: ProductListItem) => string | null;
    getPriceType: (product: ProductListItem) => PriceType;
    getPriceTypeAllowedList: (product: ProductListItem) => PriceType[];
    validatePriceType: (priceType: PriceTypeBatch, product: ProductListItem) => PriceTypeBatch;
}

const PricingContext = createContext<PricingContextData>(
    {} as PricingContextData
);

const priceValidation = [null, 0, "0", "", "0.00", "0,00"];

export const PricingProvider: React.FC<PricingProviderInterface> = ({ children }) => {
    const { state } = useCanvas();

    const handlePriceByTextType = (
        product: ProductListItem,
        ap_x_UN: ApXUNType,
        leveX_pagueY: LeveXPagueYType,
        leve_Por: LevePORType,
        xOff: XOffType,
        calculate?: boolean
    ): string[] | undefined | Error => {
        const templateType = state.comboType;
        const currentPriceType = getPriceType(product);
        const price = priceTarget(currentPriceType, product);

        if(!price) return ["0","00"];

        switch (templateType) {
            case "leve_x_pague_y":
                if (leveX_pagueY.leve <= 0 || leveX_pagueY.pague <= 0) {
                    new Error("Um ou mais campos não foram preenchidos!");
                    return ["0","00"];
                } else if (calculate) {
                    const productValue = parseFloat(price);
                    const levePrice = productValue * leveX_pagueY.leve;
                    const discountPay = levePrice - ((leveX_pagueY.leve - leveX_pagueY.pague) * productValue);
                    const discountValue = discountPay / leveX_pagueY.leve;

                    return PriceHelper.split(discountValue);
                } else return ["0","00"];

            case "ap_x_un":
                return PriceHelper.split(ap_x_UN.price);

            case "xOff":
                const discountValue = parseFloat(price) * (xOff.xOff / 100);
                const totalPrice = parseFloat(price) - discountValue;
                const truncatedPrice = Math.floor(totalPrice * 100) / 100;

                return PriceHelper.split(truncatedPrice);

            case "leve_x_por":
                const productValue = parseFloat(price) * leve_Por.quantity;
                return PriceHelper.split(productValue);

            case "economize":
                const pricePrincipal = priceTarget(state.priceType, product);
                const priceSecondary = priceTarget(state.economizePriceType || "simple", product);
                if(pricePrincipal && priceSecondary){
                    const economizeValue = parseFloat(priceSecondary) - parseFloat(pricePrincipal);
                    return PriceHelper.split(economizeValue);
                }

            default:
                return ["0","00"];
        }
    }

    function getPriceType(product: ProductListItem): PriceType {
        let productPrice: PriceType = "simple";

        if (product.sPrice && !priceValidation.includes(product.sPrice)) {
            productPrice = "simple";
        }

        if (product.oPrice && !priceValidation.includes(product.oPrice)) {
            productPrice = "offer";
        }

        if (product.cPrice && !priceValidation.includes(product.cPrice)) {
            productPrice = "club";
        }

        if (product.ePrice && !priceValidation.includes(product.ePrice)) {
            productPrice = "extra";
        }

        return productPrice;
    }

    const getPriceTypeAllowedList = (product: ProductListItem) => {
        const type: PriceType[] = [];

        if (product.sPrice && !priceValidation.includes(product.sPrice)) {
            type.push("simple");
        }
        if (product.oPrice && !priceValidation.includes(product.oPrice)) {
            type.push("offer");
        }
        if (product.cPrice && !priceValidation.includes(product.cPrice)) {
            type.push("club");
        }
        if (product.ePrice && !priceValidation.includes(product.ePrice)) {
            type.push("extra");
        }
        return type;
    }

    const priceTarget = (priceType: PriceType, product: ProductListItem) => {
        switch (priceType) {
            case "simple":
                return product.sPrice;
            case "offer":
                return product.oPrice;
            case "club":
                return product.cPrice;
            case "extra":
                return product.ePrice;
            default:
                return product.sPrice;
        }
    }

    const validatePriceType = (priceType: PriceTypeBatch  , product: ProductListItem) => {
        const allowedPrices = getPriceTypeAllowedList(product);
        //@ts-ignore
        return allowedPrices.includes(priceType) ? priceType : allowedPrices[allowedPrices.length - 1];
    }

    return (
        <PricingContext.Provider
            value={{
                handlePriceByTextType,
                priceTarget,
                getPriceType,
                getPriceTypeAllowedList,
                validatePriceType
            }}
        >
            {children}
        </PricingContext.Provider>
    );
};

export const usePricing = (): PricingContextData => {
    const context = useContext(PricingContext);

    if (!context) {
        throw new Error(
            "usePricing must be used within a PricingProvider"
        );
    }

    return context;
};
