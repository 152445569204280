import { ParentInterface } from '@/types';
import { createContext, useContext, useEffect } from "react";
import { io, Socket } from "socket.io-client";

interface WSContextData {
	socket: Socket | null;
}

const WSContext = createContext<WSContextData>({ socket: null } as WSContextData);

export const WSProvider: React.FC<ParentInterface> = ({ children }) => {
	if (!process.env.NEXT_PUBLIC_WS_URL) throw new Error("NEXT_PUBLIC_WS_URL must be set.")

	const socket = io(process.env.NEXT_PUBLIC_WS_URL);

	useEffect(() => {
		socket.on("connect", () => console.info("WebSocket socket connected."));
		socket.on("disconnect", () => console.info(socket.disconnect));

		return () => { socket.disconnect() };
	}, []);

	return (
		<WSContext.Provider value={{ socket }}>
			{children}
		</WSContext.Provider>
	);
};

export const useWS = (): WSContextData => {
	const context = useContext(WSContext);

	if (!context) {
		throw new Error("useWS must be used within a WSProvider");
	}

	return context;
};
