import { createContext, useContext, useState, useEffect, use } from 'react';
import { ReactChildren } from "@/types";
import { useMember } from "@/contexts/member/MemberContext";
import Api from "@/api/Api";
import { EntityID } from "@/types/silverstripe";
import { now } from "@/helpers";
import { DateTime, Interval } from 'luxon';
import { Campaign } from "@/types/campaigns";
import { PaperOrientation, PaperSize } from "../pdf/interfaces";

interface CompanyProviderInterface {
	children: ReactChildren
}

interface CompanyContextData {
	fetchCompany: () => Promise<void>,
	companyID: EntityID,
	setCompanyID: (entityID: EntityID) => void,
	company: CompanyInterface | null,
	allCompanies: ListCompaniesInterface | null;
	market: MarketInterface | null,
	targetCompanyID: EntityID,
	setTargetCompanyID: (entityID: EntityID) => void,
	
	packTypeList: PackOption[],

	useProductImage: boolean,
	saveToggleUseProductImage: (toggle: boolean) => void,

	useEditDescription: boolean,
	saveToggleUseEditDescription: (toggle: boolean) => void,

	useTextClub: boolean,
	saveToggleUseTextClub: (toggle: boolean) => void,

	campaigns: Campaign[],
	setCampaigns: (campaign: Campaign[]) => void,

	fetchCampaigns: () => void
}

const CompanyContext = createContext<CompanyContextData>(
	{} as CompanyContextData
);

interface MarketInterface {
	id: EntityID,
	storeID: number,
	name: string,
	totalMembers: number,
	monthlyPrints: number
}

type PackOption = {
	ID: EntityID | 'all',
	PackType: string
}

interface ListCompaniesInterface {
	id: EntityID,
	name: string,
}

export interface CompanyInterface {
	id: EntityID,
	name: string,
	licenseEnabled: boolean,
	licenseExpiration: string,
	licenseExpirationRemainingLabel: string | null,
	isLicenseValid: boolean,
	totalMarkets: number,
	totalMembers: number,
	markets: MarketInterface[],
	logos: Array<string | null>,
	slogans: Array<string | null>,
	monthlyPrints: number,
	clientAPIEnabled: boolean,
	clientAPIUrl: string | null,
	enableSalestagImageAPI: boolean,
	useBatch: boolean,
	adminCanPrint: boolean,
	batchScenario: "store_batch_control" | "manager_batch_control",
	useCompanyAutomation: boolean,
	useFuturePrice: boolean,
	useFuturePriceDefault: boolean,
	useFamilyGroup: boolean,
	useEditDescription:boolean,
	useKeepInQueue: boolean,
	useManualCalc: boolean,
	useDivisionPack: boolean,
	companyAutomation_campaign: string,
	companyAutomation_paperSize: PaperSize,
	companyAutomation_paperQuantity: number,
	companyAutomation_paperOrientation: PaperOrientation,
	companyAutomation_singlePage: boolean,
	companyAutomation_selectedPacks: [ID: number, PackType: string],
}

export const CompanyProvider: React.FC<CompanyProviderInterface> = ({ children }) => {
	const [companyID, setCompanyID] = useState<EntityID>(null);
	const [company, setCompany] = useState<CompanyInterface | null>(null);
	const [market, setMarket] = useState<MarketInterface | null>(null);
	const [useProductImage, setUseProductImage] = useState(false);
	const [useEditDescription, setUseEditDescription] = useState(false);
	const [useTextClub, setUseTextClub] = useState(false);
	const [campaigns, setCampaigns] = useState<Campaign[]>([]);
	const [allCompanies, setAllCompanies] = useState<ListCompaniesInterface | null>(null);
	const [packTypeList, setPackTypeList] = useState([]);

	// ID alvo para consultas
	const [targetCompanyID, setTargetCompanyID] = useState<EntityID>(null);

	const { member } = useMember();

	const fetchCompany = async () => {
		if (!companyID) return;
		const api = new Api('company', 'gf');
		const request = api.request(member, { companyID });
		const response = await api.post(request);
		if (response.success) {
			const { company } = response.data;
			const parsedCompany: CompanyInterface = {
				id: company.ID,
				name: company.Name,
				licenseEnabled: Boolean(company.LicenseEnabled),
				licenseExpiration: company.LicenseExpiration,
				licenseExpirationRemainingLabel: remainingTime(company.LicenseExpiration),
				isLicenseValid: Boolean(company.isLicenseValid),
				totalMarkets: company.TotalMarkets || 0,
				totalMembers: company.TotalMembers || 0,
				markets: parseMarkets(company.Markets),
				logos: parseLogos(Object.values(company.Logos)),
				slogans: Object.values(company.Slogans),
				monthlyPrints: company.MonthlyPrints,
				clientAPIUrl: company.ClientAPIUrl,
				clientAPIEnabled: Boolean(company.EnableClientAPI),
				enableSalestagImageAPI: Boolean(company.EnableSalestagImageAPI),
				useBatch: Boolean(company.UseBatch),
				adminCanPrint: Boolean(company.AdminCanPrint),
				batchScenario: company.BatchScenario,
				useCompanyAutomation: company.UseCompanyAutomation,
				useFuturePrice: Boolean(company.UseFuturePrice),
				useFuturePriceDefault: Boolean(company.UseFuturePriceDefault),
				useFamilyGroup: Boolean(company.UseFamilyGroup),
                useKeepInQueue: Boolean(company.UseKeepInQueue),
				useManualCalc: Boolean(company.UseManualCalc),
				useDivisionPack: Boolean(company.UseDivisionPack),
				useEditDescription: company.UseEditDescription,
				companyAutomation_campaign: company.CompanyAutomation_campaign,
				companyAutomation_paperSize: company.CompanyAutomation_paperSize,
				companyAutomation_paperQuantity: company.CompanyAutomation_paperQuantity,
				companyAutomation_paperOrientation: company.CompanyAutomation_paperOrientation,
				companyAutomation_singlePage: company.CompanyAutomation_singlePage,
				companyAutomation_selectedPacks: company.CompanyAutomation_selectedPacks
			}
			setUseTextClub(company.UseTextClub);
			setUseProductImage(company.UseProductImage);
			setUseEditDescription(company.UseEditDescription);
			setCompany(parsedCompany);
			setPackTypeList(company.PackTypeList);
			if (parsedCompany.markets?.length > 0) {
				parsedCompany.markets.forEach((currentMarket) => {
					if (currentMarket.id === member.marketID) setMarket(currentMarket);
				});
			}
		}
	}

	const getAllCompanies = async () => {
		if (!companyID) return;
		const api = new Api('company', 'gl');
		const request = api.request(member);
		const response = await api.post(request);
		if (response.success) {
			const { companyList } = response.data;

			if (companyList && companyList.length > 0) {
				const parsedCompanyList: ListCompaniesInterface = companyList.map((company: any) => ({
					id: company.ID,
					name: company.Name,
				}));
				setAllCompanies(parsedCompanyList); // Atualizando o estado com a lista
			}
		}
	}
	const saveToggleUseProductImage = async (toggle: boolean) => {
		const api = new Api('company', 'epi');
		const request = api.request(member, { companyID, useProductImage: toggle });
		const { success } = await api.post(request);
		success && setUseProductImage(toggle);
	}

	const saveToggleUseEditDescription = async (toggle: boolean) => {
		const api = new Api('company', 'ete');
		const request = api.request(member, { companyID, useEditDescription: toggle });
		const { success } = await api.post(request);
		success && setUseEditDescription(toggle);
	}

	const saveToggleUseTextClub = async (toggle: boolean) => {
		const api = new Api('company', 'etc');
		const request = api.request(member, { companyID, useTextClub: toggle });
		const { success } = await api.post(request);
		success && setUseTextClub(toggle);
	}

	const resetContext = () => {
		setCompanyID(null);
		setCompany(null);
	}

	// Encarrega de manter os dados atualizados.
	useEffect(() => {
		if (member.isLogged()) {
			if (!companyID && member.companyID) return setCompanyID(member.companyID);
			if (campaigns.length === 0) fetchCampaigns();
			fetchCompany();
			getAllCompanies();
		}
		else {
			resetContext();
		}
	}, [member, companyID]);

	const fetchCampaigns = () => {
		(async () => {
			const api = new Api('campaigns');
			const request = api.request(member);

			const response = await api.post(request, 'lac');
			if (response.success) {
				const { data } = response;
				const parsedCampaigns: Campaign[] = data.map((campaign: any) => ({
					id: campaign.ID,
					hash: campaign.Hash,
					name: campaign.Name,
					privacy: campaign.Privacy,
					templatesCount: campaign.TemplatesCount,
					campaignPriceType: campaign.CampaignPriceType,
					comboType: campaign.ComboType
				}));
				setCampaigns(parsedCampaigns);
			}
		})();
	}

	return (
		<CompanyContext.Provider
			value={{
				fetchCompany,
				companyID,
				setCompanyID,
				company,
				allCompanies,
				market,
				targetCompanyID,
				setTargetCompanyID,
				packTypeList,

				saveToggleUseProductImage,
				useProductImage,

				saveToggleUseEditDescription,
				useEditDescription,

				useTextClub,
				saveToggleUseTextClub,

				campaigns,
				setCampaigns,

				fetchCampaigns
			}}
		>
			{children}
		</CompanyContext.Provider>
	);
};

export const useCompany = (): CompanyContextData => {
	const context = useContext(CompanyContext);

	if (!context) {
		throw new Error(
			"useCompany must be used within a CompanyProvider"
		);
	}

	return context;
};

function parseMarkets(data: any[]): MarketInterface[] {
	return data.map(market => ({
		id: market.ID,
		storeID: market.StoreID,
		name: market.Name,
		totalMembers: market.TotalMembers || 0,
		monthlyPrints: market.MonthlyPrints
	}));
}

function parseLogos(data: Array<string | null>): Array<string | null> {
	if (!process.env.NEXT_PUBLIC_HOST_URL) {
		throw new Error("NEXT_PUBLIC_HOST_URL must be set.")
	}
	return data.map(logoUrl => (logoUrl ? `${process.env.NEXT_PUBLIC_HOST_URL}${logoUrl}?t=${now()}` : null));
}

function remainingTime(date: string): string | null {
	const now = DateTime.local();
	const targetDate = DateTime.fromISO(date);
	const difference = Interval.fromDateTimes(now, targetDate).toDuration(['years', 'months', 'days']);

	const years = Math.floor(difference.years || 0);
	const months = Math.floor(difference.months || 0) % 12;
	const days = Math.floor(difference.days || 0) % 30;

	if (years > 0)
		return `${years} ${years > 1 ? 'anos' : 'ano'}`;

	if (months > 0)
		return `${months} ${months > 1 ? 'meses' : 'mês'}`;

	if (days > 0)
		return `${days} ${days > 1 ? 'dias' : 'dia'}`;
	else if (difference.days > 0)
		return `1 dia`;

	return null;
}
