import { IColor } from "@/types/canvas";

const HistoryptIcon = ({ color }: IColor) => {
    return (
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" className={color}>
            <path d="M78.9 40.4c.1-.1.1-.3.1-.4V9c0-2.8-2.2-5-5-5H14c-2.8 0-5 2.2-5 5v60c0 2.8 2.2 5 5 5h16c.1 0 .2 0 .3-.1C34.2 86.7 46 96 60 96c17.1 0 31-13.9 31-31 0-10-4.7-18.9-12.1-24.6zM14 6h60c1.7 0 3 1.3 3 3v7H11V9c0-1.7 1.3-3 3-3zm0 66c-1.7 0-3-1.3-3-3V18h66v21c-3.3-2.1-7-3.7-11-4.5V30c0-.4-.2-.7-.5-.9-.3-.2-.7-.2-1 0l-13 8c-.3.2-.5.6-.5.9 0 .3.2.7.5.9l13 8c.3.2.7.2 1 0 .3-.2.5-.5.5-.9v-4.2C76.5 44.5 84 54 84 65c0 13.2-10.8 24-24 24S36 78.2 36 65c0-8.4 4.5-16.3 11.7-20.6.4-.3.6-.8.4-1.3l-2-4.6c-.1-.3-.3-.5-.6-.5-.3-.1-.6-.1-.8.1C35 43.5 29 53.9 29 65c0 2.4.3 4.7.8 7H14zm46 22c-16 0-29-13-29-29 0-10 5.2-19.4 13.7-24.6l1.2 2.8C38.6 47.9 34 56.2 34 65c0 14.3 11.7 26 26 26s26-11.7 26-26c0-12.3-8.7-23-20.8-25.5-.3-.1-.6 0-.8.2-.2.2-.4.5-.4.8v3.7L53.9 38 64 31.8v3.6c0 .5.4.9.8 1C78.8 38.8 89 50.8 89 65c0 16-13 29-29 29z" />
            <path d="M60 69c1.9 0 3.4-1.3 3.9-3H79c.6 0 1-.4 1-1s-.4-1-1-1H63.9c-.4-1.4-1.5-2.5-2.9-2.9V52c0-.6-.4-1-1-1s-1 .4-1 1v9.1c-1.7.4-3 2-3 3.9 0 2.2 1.8 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2z" />
            <circle cx={71} cy={11} r={2} />
            <circle cx={65} cy={11} r={2} />
            <circle cx={59} cy={11} r={2} />
            <path d="M23 21h-7c-.6 0-1 .4-1 1s.4 1 1 1h7c.6 0 1-.4 1-1s-.4-1-1-1zM23 25h-7c-.6 0-1 .4-1 1s.4 1 1 1h7c.6 0 1-.4 1-1s-.4-1-1-1zM23 29h-7c-.6 0-1 .4-1 1s.4 1 1 1h7c.6 0 1-.4 1-1s-.4-1-1-1z" />
        </svg>
    );
}

export default HistoryptIcon;