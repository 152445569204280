import { createContext, useContext, useState, useEffect, useRef } from "react";
import { ReactChildren } from "@/types";
import Api from "@/api/Api";
import { useMember } from "../member/MemberContext";
import { ProductListItem, SilverProduct } from "@/types/products";
import SystemInfo from "@/helpers/SystemInfo";
import { generateDeterministicUUID } from "@/helpers";
import useBoolean from "@/hooks/useBoolean";
import Utils from "@/helpers/Utils";
import { useCompany } from "@/contexts/company/CompanyContext";
import { useWS } from "../ws/WSContext";
import Notify from "@/helpers/Notify";
import { EntityID } from "@/types/silverstripe";
import { Campaign } from "@/types/campaigns";
import { ImgMapping } from "@/components/modals/config/components/SettingsManager/PersonalProdImg";
import { usePathname } from "next/navigation";
import { formatDate } from "@/components/buttons/DateFormatter";
import { PriceType } from "@/types/canvas";

type SearchType = "historic" | "integration" | "client_bridge";

interface SearchProviderInterface {
	children: ReactChildren
}

export interface ITemplate {
	CampaignID: number,
	Hash: string,
	Name: string,
	Orientation: string,
	Preview: string,
	PriceType: PriceType,
	EconomizePriceType: PriceType,
	Privacy: string,
	ProdQuantiy: number
	ComboType: string
}

export interface PackOption  {
	ID: EntityID | 'all',
	PackType: string
}

interface SearchContextData {
	productSearch: (term: string) => void,
	searchResults: ProductListItem[],
	setSearchResults: (results: ProductListItem[]) => void,
	searchType: SearchType,
	setSearchType: (type: SearchType) => void,
	isSearching: boolean,
	currentPage: number,
	setCurrentPage: (type: number) => void,
	removeProductFromHistoric: (productID: EntityID) => void,

	registeredProducts: ProductListItem[],
	setRegisteredProducts: (products: ProductListItem[]) => void;

	clientBridgeProducts: ProductListItem[],
	setClientBridgeProducts: (products: ProductListItem[]) => void;

	selectedCampaign: Campaign | undefined,
	setSelectedCampaign: (campaign: Campaign) => void,

	selectedPack:PackOption | undefined,
	setSelectedPack: (pack: PackOption) => void,

	templatesFromSelectedCampaign: ITemplate[],
	setTemplatesFromSelectedCampaign: (campaign: ITemplate[]) => void
}

const SearchContext = createContext<SearchContextData>(
	{} as SearchContextData
);

export const SearchProvider: React.FC<SearchProviderInterface> = ({ children }) => {
	const api = new Api('search');
	const { member } = useMember();
	const { company, useProductImage } = useCompany();

	const { socket } = useWS();
	const path = usePathname();

	const appliedInitConf = useRef(false);

	// Resultados da busca atual
	const [searchResults, setSearchResults] = useState<ProductListItem[]>([]);

	// Tipo de busca CSV ou integração
	const [searchType, setSearchType] = useState<SearchType>("client_bridge");

	// Indicador de carregamento
	const { value: isSearching, on, off } = useBoolean(false);

	const [currentPage, setCurrentPage] = useState(1);

	const [registeredProducts, setRegisteredProducts] = useState<ProductListItem[]>([]);
	const [clientBridgeProducts, setClientBridgeProducts] = useState<ProductListItem[]>([]);

	const [selectedCampaign, setSelectedCampaign] = useState<Campaign | undefined>(undefined);
	const [selectedPack, setSelectedPack] = useState<PackOption | undefined>(undefined);
	
	const [templatesFromSelectedCampaign, setTemplatesFromSelectedCampaign] = useState<ITemplate[]>([]);

	const selectedPackRef = useRef<PackOption | undefined>(selectedPack);

	const productSearch = async (term: string) => {
		if (!term) return;

		setSearchResults([]);

		const searchFunctions = {
			historic: historicSearch,
			client_bridge: clientBridgeSearch,
			integration: integrationSearch,
		};

		const searchFunction = searchFunctions[searchType];
		if (!searchFunction) throw new Error("Invalid search type");

		on();
		const response = await searchFunction(term);

		const items: ProductListItem[] = response.map((r: SilverProduct) =>
			extractSilverProducts(r)
		);
		setSearchResults(items);
	};

	const historicSearch = async (term: string) => {
		const request = api.request(member, {
			term: term,
			index: currentPage
		});

		const response = await api.post(request, "p");
		off();
		if (!response.success) return [];

		return response.data.results;
	};

	const removeProductFromHistoric = (productID: EntityID) => {
		const productRemoved = searchResults.filter(prod => prod.id !== productID);
		setSearchResults(productRemoved);
		setRegisteredProducts(productRemoved);
	}

	const clientBridgeSearch = async (term: string) => {
		const apiGetCompany = new Api("client");

		const request = apiGetCompany.request(member, {
			companyID: member.companyID
		})

		const response = await apiGetCompany.post(request, "g");

		if (response.success) {
			if (socket == null) {
				Notify.Warn("Não foi possível se conectar ao servidor.");
				off();
				return;
			};

			const token = response.data.token;

			socket.emit("sendToJava", {
				term: term,
				market_id: [member.marketID],
				page: currentPage,
				hash: socket.id,
				token: token
			}, token);

			socket.on("error", (msg) => {
				off();
				console.log(msg);
				Notify.Error("Erro de comunicação com a API.");
			})
		} else {
			off();
		}

		return [];
	};

	const integrationSearch = async (term: string) => {
		const iApi = new Api("connection");

		const request = iApi.request(member, {
			term: term,
		});

		const response = await iApi.post(request, "get-products");
		if (!response.success) return [];

		off();
		return response.data.results;
	};

	useEffect(() => {
		if (searchType === "historic") {
			setClientBridgeProducts(searchResults);
			setSearchResults(registeredProducts);
		}

		if (searchType === "client_bridge") {
			setRegisteredProducts(searchResults);
			setSearchResults(clientBridgeProducts);
		}
	}, [searchType]);

	useEffect(() => {
		if (!member.isLogged() || !company) return;

		if (
			appliedInitConf.current === false &&
			(SystemInfo.isIntranet || company.clientAPIEnabled)
		) {
			setSearchType(company.clientAPIEnabled ? "client_bridge" : "integration");
			appliedInitConf.current = true;
		}
	}, [member]);

	useEffect(() => {
		if (socket) {
			socket.on("search-result", response => {
				if (response.success) {
					let productsImg: ImgMapping[] = [];

					try {
						const storedData = localStorage.getItem('products-img');
						productsImg = storedData ? JSON.parse(storedData) : [];
					} catch (e) {
						console.error('Error parsing JSON from localStorage:', e);
						productsImg = [];
					}

					const items: ProductListItem[] = response.data.
						filter((prod: SilverProduct) => {
							return selectedPackRef.current ? prod.PackType === selectedPackRef.current.PackType : true;
						})
						.map((prod: SilverProduct) => {
						const prodImg = productsImg.find((el: { ean: string | null; }) => String(el.ean) === String(prod.Ean));

						if (prodImg) prod.ImageURL = prodImg.url;
						if (useProductImage) {
							prod.ImageURL = `https://imageapi.salestag.com.br:9020/assets/CovPgImgs/${prod.Ean}.png`;
						}

						return extractSilverProducts(prod)
					});
					setSearchResults(items);
					off();
				} else {
					off();
				}
			})
		}
	}, [socket]);

	useEffect(() => {
		selectedPackRef.current = selectedPack;
	}, [selectedPack]);

	return (
		<SearchContext.Provider
			value={{
				productSearch,
				searchResults,
				setSearchResults,
				searchType,
				setSearchType,
				isSearching,
				currentPage,
				setCurrentPage,
				removeProductFromHistoric,

				registeredProducts,
				setRegisteredProducts,
				clientBridgeProducts,
				setClientBridgeProducts,

				selectedCampaign,
				setSelectedCampaign,

				selectedPack,
				setSelectedPack,

				templatesFromSelectedCampaign,
				setTemplatesFromSelectedCampaign
			}}
		>
			{children}
		</SearchContext.Provider>
	);
};

export const useSearch = (): SearchContextData => {
	const context = useContext(SearchContext);

	if (!context) {
		throw new Error(
			"useSearch must be used within a SearchProvider"
		);
	}

	return context;
};

function formatPrice(price: string | number | null | undefined) {
	if (price === null || price === undefined) return price;
	return parseFloat(price.toString()).toFixed(2);
}

export function extractSilverProducts(data: SilverProduct): ProductListItem {
	const { Ean = '0000000000000', Code = 'xxxxxx', Description = '' } = data;

	return {
		id: Utils.isConsideredEmpty(data.ID) // @ts-ignore
			? generateDeterministicUUID(Ean + Code + Description.replaceAll(' ', ''))
			: parseInt(String(data.ID)),
		ean: String(data.Ean),
		innerCode: data.Code,
		description: data.Description,
		shortDescription: data.ShortDescription,
		descriptionConnect: data.DescriptionConnect,
		idFamily: Utils.isConsideredEmpty(data.IdFamily) // @ts-ignore
			? 0 : parseInt(String(data.IdFamily)),
		sPrice: formatPrice(data.SPrice),
		cPrice: formatPrice(data.CPrice),
		oPrice: formatPrice(data.OPrice),
		ePrice: formatPrice(data.EPrice),
		packType: data.PackType,
		priceType: data.PriceType,
		packQuantity: data.PackQuantity,
		preview: data.ImageURL,
		...Object.fromEntries(
			Object.entries(data)
				.filter(([key]) => key.startsWith('ExtraParameter_'))
				.map(([key, value]) => [key.toLowerCase(), value])
		),
		initDate: data.InitDate ? formatDate(String(data.InitDate)) : undefined, //converte o formato data para dd/mm/aaaa
		endDate: data.InitDate ? formatDate(String(data.EndDate)) : undefined, //converte o formato data para dd/mm/aaaa
		marketID: data.MarketID,
		wantUseProductPerGram: data.wantUseProductPerGram,
		dueDate: data.dueDate,
		limitationQuantity: data.cpfLimit
	} as unknown as ProductListItem;
}
