export function formatDate(input: string): string {
    const formats = [
        "YYYY-MM-DD", // 2024-09-25
        "YYYY/MM/DD", // 2024/09/25
        "DD-MM-YYYY", // 25-09-2024
        "DD/MM/YYYY"  // 25/09/2024
    ];

    for (const format of formats) {
        const date = parseDate(input, format);
        if (date) {
            return formatDateOutput(date);
        }
    }

    return input;
}

function parseDate(input: string, format: string): Date | null {
    const regex = createRegex(format);
    const match = input.match(regex);

    if (match) {
        const yearIndex = format.indexOf("YYYY") >= 0 ? format.indexOf("YYYY") : -1;
        const monthIndex = format.indexOf("MM") >= 0 ? format.indexOf("MM") : -1;
        const dayIndex = format.indexOf("DD") >= 0 ? format.indexOf("DD") : -1;

        const year = yearIndex !== -1 ? parseInt(match[0].slice(yearIndex, yearIndex + 4)) : 0;
        const month = monthIndex !== -1 ? parseInt(match[0].slice(monthIndex, monthIndex + 2)) - 1 : 0;
        const day = dayIndex !== -1 ? parseInt(match[0].slice(dayIndex, dayIndex + 2)) : 0;

        return new Date(year, month, day);
    }
    return null;
}

function createRegex(format: string): RegExp {
    const regexStr = format
        .replace(/YYYY/g, "(\\d{4})")
        .replace(/MM/g, "(\\d{2})")
        .replace(/DD/g, "(\\d{2})");
    return new RegExp(`^${regexStr}$`);
}

function formatDateOutput(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}
