import Api from "@/api/Api";
import { createContext, useContext, useEffect, useState } from "react";
import { useMember } from "../member/MemberContext";
import { useCanvas } from "../editor/CanvasContext";
import { useQueue } from "../queue/QueueContext";
import { Orientation } from "@/types/canvas";
import PDF from "@/classes/PDF";
import { APIImageQueue } from "@/types/queue";
import { uuid } from "@/helpers";
import Notify from "@/helpers/Notify";
import { PaperSize } from "../pdf/interfaces";
import { useCompany } from "../company/CompanyContext";
import { DateTime } from "luxon";

interface Item {
    label: string;
    value: number;
}

interface ItemsByOrientation {
    vertical: string[];
    horizontal: string[];
    p21: string[];
    p30: string[];
}
interface HistoricProviderInterface {
    children: React.ReactNode;
}

interface HistoricContextData {
    dateIn: string;
    setDateIn: (date: string) => void;
    campaigns: Item;
    setCampaigns: (campaigns: Item) => void;
    stores: Item;
    setStores: (store: Item) => void;
    postSize: Item;
    setPostSize: (size: Item) => void;
    description: string;
    setDescription: (description: string) => void;
    searchResults: any[];
    setSearchResults: React.Dispatch<React.SetStateAction<any[]>>;
    selectedItems: string[];
    setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
    fetchHistory: () => Promise<void>;
    generatePdf: () => Promise<void>;
    prepareItens: () => Promise<void>;
    showModalPrint: boolean;
    setShowModalPrint: (value: boolean) => void;
    needsClick: boolean;
    itensToPrint: any[];
    isGeneratingPdf: boolean;
}

const HistoricContext = createContext<HistoricContextData>({} as HistoricContextData);

export const HistoricProvider: React.FC<HistoricProviderInterface> = ({ children }) => {

    const { company } = useCompany();
    const { member } = useMember();
    const {
        state,
        reloadTemplate,
        updateProductTextElements,
        updateProductImageElements
    } = useCanvas();
    const { products: prodID } = state;
    const { getImageToHistory } = useQueue();

    const papers: PaperSize[] = ["A3", "A4", "A5", "A6", "A7", "_2A3", "_2A4"];
    const companyName = company?.name;

    const [dateIn, setDateIn] = useState<string>("");
    const [stores, setStores] = useState<Item>({label: "Todas as Lojas", value: 0});
    const [postSize, setPostSize] = useState<Item>({label: "Todas", value: 0});
    const [campaigns, setCampaigns] = useState<Item>({label: "Todas", value: 0});
    const [description, setDescription] = useState<string>("");

    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [showModalPrint, setShowModalPrint] = useState<boolean>(false);
    const [itensToPrint, setItensToPrint] = useState<any[]>([]);
    const [needsClick, setNeedsClick] = useState<boolean>(true);
    const [isGeneratingPdf, setIsGeneratingPdf] = useState<boolean>(false);
    const [itemsByOrientation, setItemsByOrientation] = useState<ItemsByOrientation>({
        vertical: [],
        horizontal: [],
        p21: [],
        p30: []
    });

    const [getNextHashState, setNextHashState] = useState<string>("");

    useEffect(() => {
        const today = DateTime.now().toISODate();
        if (today) {
            setDateIn(today);
            setStores({label: "Todas as Lojas", value: 0});
        }
    }, [member]);

    useEffect(() => {
        fetchHistory();
    }, [dateIn, stores, postSize, campaigns, description]);

    const fetchHistory = async () => {
        setSelectedItems([]); // reset boxes on search
        const api = new Api("history", "ghi");
        const request = api.request(member, { dateIn, markets: stores?.value, postSize: postSize.label, campaignID: campaigns.value, description });
        const response = await api.post(request);

        setSearchResults(response.code === "success" ? response.data.historylist : []);
    };

    const prepareItens = async () => {
        if(!selectedItems.length) return;
        setShowModalPrint(true);

        let itemns = searchResults.filter(item => selectedItems.includes(item.ID));
        const groupedItems = itemns.reduce((acc: any, item: any) => {
        const { TemplateHash, CampaignName } = item;
        const campaignKey = (CampaignName).trim();

        if (!acc[campaignKey]) {
            acc[campaignKey] = {
                TemplateHash,
                items: [],
                itemsCount: 0
            };
        }

        acc[campaignKey].items.push(item);
        acc[campaignKey].itemsCount++;

        // Ordenando os items por TemplateHash
        acc[campaignKey].items.sort((a: any, b: any) =>
            a.TemplateHash.localeCompare(b.TemplateHash)
        );

        return acc;
    }, {});

        setItensToPrint(groupedItems);
        const getFirstHash = Object.values(groupedItems as { TemplateHash: string }[])[0]?.TemplateHash;

        await reloadTemplate(getFirstHash);
    };

    //prepare items in array separated by campaign
    const generatePdf = async () => {
        if(state.campaignName) {
            setIsGeneratingPdf(true);
            setNeedsClick(false);

            const currentCampaignName = (state.campaignName).trim() as any;

            const itemnsGenImage = itensToPrint[currentCampaignName];
            const updatedItemsByOrientation = { ...itemsByOrientation };

            for (const itemImages of itemnsGenImage.items) {

                if(itemImages.TemplateHash === state.templateId){
                    updateProductImageElements(prodID.ids[0], itemImages.Product);
                    await updateProductTextElements(prodID.ids[0], itemImages.Product, itemImages.PrintedTime);

                    const updatedImage = (await getImageToHistory(itemImages));
                    const orientation = itemImages.orientation.toLowerCase();

                    if (!updatedItemsByOrientation[orientation as keyof typeof updatedItemsByOrientation]) {
                        updatedItemsByOrientation[orientation as keyof typeof updatedItemsByOrientation] = [];
                    }

                    if (updatedImage != null && updatedImage) updatedItemsByOrientation[orientation as keyof typeof updatedItemsByOrientation].push(updatedImage);

                    Object.values(itensToPrint).forEach(campaign => {
                        const { items } = campaign;
                        // Filtra os itens, removendo aqueles com o TemplateHash desejado
                        const updatedItems = items.filter((item: { TemplateHash: string }) => item.TemplateHash !== itemImages.TemplateHash);

                        if (updatedItems.length !== items.length) {
                            // Se algum item foi removido, atualiza o items e o itemsCount
                            campaign.items = updatedItems;
                            campaign.itemsCount = updatedItems.length;

                            // Se houver um próximo TemplateHash, define no pai
                            if (updatedItems[0]) {
                                campaign.TemplateHash = updatedItems[0].TemplateHash;
                            } else {
                                campaign.TemplateHash = null;
                            }
                        }
                    });

                    setItensToPrint(itensToPrint);
                } else {
                    await reloadTemplate(itemImages.TemplateHash);
                    await new Promise(resolve => setTimeout(resolve, 800));
                    setNeedsClick(true);
                    return;
                }
            }
            setItemsByOrientation(updatedItemsByOrientation);

            delete itensToPrint[currentCampaignName];
            setItensToPrint(itensToPrint);
            const getNextHash = Object.values(itensToPrint as { TemplateHash: string }[])[0]?.TemplateHash;

            if (getNextHash) {
                await reloadTemplate(getNextHash);
                await new Promise(resolve => setTimeout(resolve, 800));
                setNeedsClick(true);
            } else {
                returnPdf();
                setShowModalPrint(false);
                return;
            }
        }
    }

    const returnPdf = async () => {

        const api = new Api("queue", "st");

        for (const orientations in itemsByOrientation) {
            const items = itemsByOrientation[orientations as keyof typeof itemsByOrientation];
            let papervalidated = papersValidation(items);

            if (items.length > 0) {
                for (const paper of papers) {

                    const formData = await PDF.create({ paper, orientation: orientations as Orientation, imageQueue: items as unknown as APIImageQueue, singlePage: paper === "A3" ? true : false, papersNotAvailable: papervalidated });
                    if (formData) {
                        const { _r } = Api.encryptRequest({
                            template: paper + "_" + uuid(),
                            company: companyName,
                            templates: items,
                            printedBy: member.fullName
                        });

                        formData.append("_r", _r);

                        const { success, data } = await api.postBlob(formData);
                        if(!success) {
                            Notify.error("Parece que encontramos um probleminha ao gerar seu PDF.").center();
                            return;
                        }
                        window.open(data.pdfUrl, '_blank');
                    }
                }
            }
        }
        //reset
        setIsGeneratingPdf(false);
        setSelectedItems([]);
        setItensToPrint([]);
        setItemsByOrientation({ vertical: [], horizontal: [], p21: [], p30: [] });
    }

    const papersValidation = (queue: any[]): PaperSize[] => {
        const papersNotAvailable = new Set<PaperSize>();
        const papersAvailable = new Set<PaperSize>();

        queue.forEach(paper => {
            papers.forEach( size => {
                if (paper[size] === 0) {
                    papersNotAvailable.add(size);
                } else {
                    papersAvailable.add(size);
                }
            });
        });

        papersAvailable.forEach(size => {
            if (papersNotAvailable.has(size)) {
                papersNotAvailable.delete(size);
            }
        });

        return Array.from(papersNotAvailable);
    }

    return (
        <HistoricContext.Provider
            value={{
                dateIn,
                setDateIn,
                campaigns,
                setCampaigns,
                stores,
                setStores,
                postSize,
                setPostSize,
                searchResults,
                setSearchResults,
                fetchHistory,
                selectedItems,
                setSelectedItems,
                generatePdf,
                prepareItens,
                showModalPrint,
                setShowModalPrint,
                needsClick,
                description,
                setDescription,
                itensToPrint,
                isGeneratingPdf
            }}
        >
            {children}
        </HistoricContext.Provider>
    );
};

export const useHistoric = (): HistoricContextData => {
    const context = useContext(HistoricContext);

    if (!context) {
        throw new Error(
            "useHistoric must be used within a HistoricProvider"
        );
    }

    return context;
}