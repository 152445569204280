export default class PriceHelper {
	static mockEAN(): string {
		let eanCode = '';
		for (let i = 0; i < 13; i++) {
			eanCode += Math.floor(Math.random() * 10);
		}
		return eanCode;
	}

	static isPriceValid(price: string | null): boolean {
		return !!price && price.length > 0 && price !== '0' && price !== '-';
	}

	static split(valor: number | string): string[] {
		if (typeof valor === "number") {
			if (Number.isInteger(valor)) {
				return [String(valor), "00"];
			} else {
				return valor.toFixed(2).split(".");
			}
		}
	
		if (typeof valor === "string") {
			if (valor.includes(".")) return valor.split(".");
			if (valor.includes(",")) return valor.split(",");
		}
	
		return ["0", "00"];
	}
	// static split(valor: number | string): string[] {
	// 	switch (true) {
	// 		case typeof valor === "number" && Number.isInteger(valor):
	// 			return [String(valor), npm "00"];

	// 		case typeof valor === "number" && !Number.isInteger(valor):
	// 			const valorStr = (valor as number).toFixed(2);
	// 			return valorStr.split(".");

	// 		case typeof valor === "string" && valor.includes("."):
	// 			return valor.split(".");

	// 		case typeof valor === "string" && valor.includes(","):
	// 			return valor.split(",");

	// 		default:
	// 			return ["0", "00"];
	// 	}
	// }
}
