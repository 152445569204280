import { createContext, useContext, useState, useEffect } from "react";
import { ReactChildren } from "@/types";
import { EntityID } from "@/types/silverstripe";
import { AllowedPackType, FieldOption, ICSVCustomFields, ProductListItem, __FIELD_OPTIONS__ } from "@/types/products";
import { ItemListType } from "../models/ModelsContext";
import _ from "lodash";
import ArrayHelper from "@/helpers/ArrayHelper";
import Api from "@/api/Api";
import { useMember } from "../member/MemberContext";
import { useCompany } from "../company/CompanyContext";

interface ProductsContextInterface {
	targetID: EntityID;
	setTargetID: (id: EntityID) => void;
	description: string;
	setDescription: (value: string) => void;
	shortDescription: string;
	setShortDescription: (value: string) => void;
	eanCode: string;
	setEanCode: (value: string) => void;
	innerCode: string;
	setInnerCode: (value: string) => void;
	packType: AllowedPackType;
	setPackType: (value: AllowedPackType) => void;
	productImage: string | null;
	setProductImage: (value: string | null) => void;

	sPrice: string;
	setSPrice: (value: string) => void;
	cPrice: string;
	setCPrice: (value: string) => void;
	oPrice: string;
	setOPrice: (value: string) => void;
	ePrice: string;
	setEPrice: (value: string) => void;

	shouldUpdateLists: boolean;
	setShouldUpdateLists: (value: boolean) => void;

	showProductModal: boolean;
	setShowProductModal: (value: boolean) => void;

	showInternalProductModal: boolean;
	setShowInternalProductModal: (value: boolean) => void;

	chosenModal: boolean;
	setChosenModal: (value: boolean) => void;

	isProductModalVisible: boolean;
	setIsProductModalVisible: (value: boolean) => void;

	isBannerFounderModalVisible: boolean;
	setIsBannerFounderModalVisible: (value: boolean) => void;

	isBannerRulerModalVisible: boolean;
	setIsBannerRulerModalVisible: (value: boolean) => void;

	bannerToFound: ItemListType | null;
	setBannerToFound: (value: ItemListType | null) => void;

	isTableEmpty: boolean;
	setIsTableEmpty: (value: boolean) => void;

	productsPrint: ProductListItem[];
	setProductsPrint: (addProduct: ProductListItem[]) => void;

	addProductPrint: (product: ProductListItem) => void;
	removePrintProductBy: (key: keyof ProductListItem, value: any) => void;

	resetContext: () => void;

	customFields: ICSVCustomFields[];
	setCustomFields: (customField: ICSVCustomFields[]) => void;

	getConvertedCustomFieldsToProductFields: () => FieldOption[];
	requestCSVCustomFields: () => void;
}

interface ProductsProviderInterface {
	children: ReactChildren
}

const ProductsContext = createContext<ProductsContextInterface>(
	{} as ProductsContextInterface
);

export const ProductsProvider: React.FC<ProductsProviderInterface> = ({ children }) => {
	const { useProductImage } = useCompany();

	const { member } = useMember();
	const [targetID, setTargetID] = useState<EntityID>(null);
	const [description, setDescription] = useState("");
	const [shortDescription, setShortDescription] = useState("");
	const [eanCode, setEanCode] = useState("");
	const [innerCode, setInnerCode] = useState("");
	const [packType, setPackType] = useState<AllowedPackType>("un");
	const [productImage, setProductImage] = useState<string | null>(null);
	const [isTableEmpty, setIsTableEmpty] = useState(false);
	const [productsPrint, setProductsPrint] = useState<ProductListItem[]>([]);
	const [customFields, setCustomFields] = useState<ICSVCustomFields[]>([]);

	const [sPrice, setSPrice] = useState("");
	const [cPrice, setCPrice] = useState("");
	const [oPrice, setOPrice] = useState("");
	const [ePrice, setEPrice] = useState("");

	const [shouldUpdateLists, setShouldUpdateLists] = useState(false);

	// Modais.
	const [showProductModal, setShowProductModal] = useState(false);
	const [showInternalProductModal, setShowInternalProductModal] = useState(false);
	const [chosenModal, setChosenModal] = useState(false);
	const [isProductModalVisible, setIsProductModalVisible] = useState(false);

	const [isBannerFounderModalVisible, setIsBannerFounderModalVisible] = useState(false);
	const [bannerToFound, setBannerToFound] = useState<ItemListType | null>(null);

	const [isBannerRulerModalVisible, setIsBannerRulerModalVisible] = useState(false);

	const getProductImg = async () => {
		if (member.isLogged()) {
			const api = new Api('products-img', 'l');

			const request = api.request(member);
			const { success, data } = await api.post(request);

			if (!data && !success) return;

			if (data?.result) localStorage.setItem('products-img', JSON.stringify(data.result));
		}
	}

	function productAlreadyIncluded(product: ProductListItem) {
		return _.some(productsPrint, product);
	}

	const addProductPrint = (product: ProductListItem) => {
		if (!product || productAlreadyIncluded(product)) return;

		setProductsPrint((prevState) => [...prevState, product]);
	};

	const removePrintProductBy = (key: keyof ProductListItem, value: any) => {
		const list = ArrayHelper.removeByPropertyValue(
			productsPrint,
			key,
			value
		);
		setProductsPrint(list);
	};

	const resetContext = () => {
		setTargetID(null);
		setDescription("");
		setShortDescription("");
		setEanCode("");
		setInnerCode("");
		setSPrice("");
		setCPrice("");
		setOPrice("");
		setEPrice("");
		setPackType("un");
		setProductImage(null);
	};

	const requestCSVCustomFields = async () => {
		const api = new Api("csv", "g");
		const request = api.request(member, { companyID: member.companyID });
		const { success, data } = await api.post(request);
		if (success) {
			const csvCustomFields = removeNullFields(data);
			setCustomFields(csvCustomFields);
		}
	};

	const removeNullFields = (fields: FieldOption): ICSVCustomFields[] => {
		// converte o objeto em um array de objeto, removendo os parametros que forem null.
		const adjustTheFields: ICSVCustomFields[] = Object.entries(fields)
			.filter(([_, name]) => name !== null)
			.map(([param, name]) => ({
				name: param as keyof ProductListItem,
				param: name,
			}));

		return adjustTheFields;
	};

	const getConvertedCustomFieldsToProductFields = (): FieldOption[] => {
		const fieldOptions = __FIELD_OPTIONS__;

		if (customFields) {
			const adjustTheField: FieldOption[] = Object.entries(
				customFields
			).map(([_, name]) => ({
				value: name.name as keyof ProductListItem,
				label: name.param as string,
			}));

			return [...fieldOptions, ...adjustTheField];
		}

		return fieldOptions;
	};

	useEffect(() => {
		if (member && member.companyID && useProductImage) {
			getProductImg();
		}
	}, [member]);

	useEffect(() => {
		return resetContext();
	}, []);

	return (
		<ProductsContext.Provider
			value={{

				targetID,
				setTargetID,
				description,
				setDescription,
				shortDescription,
				setShortDescription,
				eanCode,
				setEanCode,
				innerCode,
				setInnerCode,
				packType,
				setPackType,
				productImage,
				setProductImage,

				sPrice,
				setSPrice,
				cPrice,
				setCPrice,
				oPrice,
				setOPrice,
				ePrice,
				setEPrice,

				chosenModal,
				setChosenModal,

				isProductModalVisible,
				setIsProductModalVisible,

				isBannerFounderModalVisible,
				setIsBannerFounderModalVisible,

				isBannerRulerModalVisible,
				setIsBannerRulerModalVisible,

				bannerToFound,
				setBannerToFound,

				shouldUpdateLists,
				setShouldUpdateLists,
				showProductModal,
				setShowProductModal,
				showInternalProductModal,
				setShowInternalProductModal,

				isTableEmpty,
				setIsTableEmpty,

				productsPrint,
				setProductsPrint,

				addProductPrint,
				removePrintProductBy,

				resetContext,

				customFields,
				setCustomFields,

				getConvertedCustomFieldsToProductFields,
				requestCSVCustomFields,

			}}
		>
			{children}
		</ProductsContext.Provider>
	);
};

export const useProducts = (): ProductsContextInterface => {
	const context = useContext(ProductsContext);

	if (!context) {
		throw new Error(
			"useProducts must be used within an ProductsProvider"
		);
	}

	return context;
};
