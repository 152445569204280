import React, { useRef, useState } from 'react';
import ImageHelper from '@/helpers/ImageHelper';
import Notify from '@/helpers/Notify';

interface DropzoneInterface {
	onChange: (uri: string | null) => void;
	defaultUri?: string | null;
	disableUpload?: boolean;
}

const Dropzone: React.FC<DropzoneInterface> = ({ onChange, defaultUri, disableUpload }) => {
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const [isDragging, setIsDragging] = useState(false);
	const [uri, setUri] = useState<string | null>(defaultUri || null);

	const handleFileSelect = async (file: File) => {
		if (file) {
			const base64Image = await ImageHelper.toBase64(file);
			setUri(base64Image);
			onChange(base64Image);
		}
	};

	const setFile = (file: File) => {
		if (file) {
			const extension = file.name.split('.').pop() ?? '';
			if (['png', 'jpeg', 'jpg', 'svg', 'webp'].includes(extension)) {
				handleFileSelect(file);
			}
			else {
				Notify.Error('extension: ' + extension + ' is not supported');
			}
		}
	}

	const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.stopPropagation();
		if (event.target?.files?.length)
			setFile(event.target.files[0]);
	};

	const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
		setIsDragging(true);
		event.preventDefault();
	};

	const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setIsDragging(false);
		if (event.dataTransfer?.files?.length)
			setFile(event.dataTransfer.files[0]);
	};

	const resetFile = () => {
		setUri(null);
		onChange(null);
	}

	return (
		<React.Fragment>
			{
				uri ? (
					<div className='flex static flex-col items-center justify-center sm:flex-row rounded-2xl duration-300' >
						<img src={uri} alt="Uploaded image preview" className="w-full h-[7.8rem] bg-cover" />
					</div>
				)
					: disableUpload ? <DisabledUploadContainer />
						: (
							<div
								className={`flex items-center w-full justify-center ${isDragging && 'scale-101'} duration-300`}
								onDrop={onDrop}
								onDragOver={onDragOver}
								onDragLeave={() => setIsDragging(false)}
							>
								<div className="w-full p-2">
									<label htmlFor="dropzone-file"
										className={`flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer ${isDragging ? 'bg-gray-200' : 'bg-gray-50'} hover:bg-gray-100 dark:border-gray-600`}>
										<div className="flex flex-col items-center justify-center pt-5 pb-6">
											<svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
											<p className="w-full text-sm text-center text-gray-500 dark:text-gray-400"><span className="font-semibold">Clique ou arraste</span> seus arquivos pra cá.</p>
											<p className="text-xs text-center text-gray-500 dark:text-gray-400">SVG, PNG, ou JPG (MAX. 800x400px)</p>
										</div>
										<input id="dropzone-file"
											type="file"
											className="hidden"
											ref={fileInputRef}
											onChange={onFileChange}
										/>
									</label>
								</div>
							</div>
						)
			}
		</React.Fragment>
	)

}

Dropzone.defaultProps = {
	defaultUri: null,
	disableUpload: false
}

export default Dropzone;

const DisabledUploadContainer: React.FC = () => {
	return (
		<div className='flex flex-row mt-10 p-3 rounded-2xl duration-300 bg-slate-100 hover:bg-slate-200' >
			<img src={'/images/no-img.png'} alt="Uploaded image preview" width={250} height={250} />
		</div>
	)
}
