import React, { createContext, useState, useContext, useEffect } from 'react';
import { ReactChildren } from "@/types";
import { APIResponse, IMember, MemberProps, UserPermissions, UserRoles } from '@/types/member';
import { EntityID } from "@/types/silverstripe";
import useCookies from '@/hooks/useCookies';
import Api from '@/api/Api';
import Notify from '@/helpers/Notify';
import { useRouter } from 'next/router';

interface IMemberProvider {
	children: ReactChildren;
}

const defaultPermissions: UserPermissions = {
	create_banner: false,
	send_to_queue: false,
	print_queue: false,
	send_to_any_queue: false,
	manage_company: false,
	manage_products: false,
	manage_public_assets: false,
	upload_fonts: false,
	upload_images: false,
	manage_island: false,
	generate_video: false,
	edit_banner_texts: false,
	show_open_banner_button: false,
	show_packFilter_print_page: false,
}

export const defaultMember: IMember = {
	id: null,
	hash: null,
	avatar: null,
	email: '',
	firstName: '',
	lastName: null,
	fullName: '',
	memberID: null,
	companyID: null,
	marketID: null,
	isAdmin: false,
	role: null,
	finishOnboarding: false,
	valid: false,
	expires: 0,
	remaining: 0,
	maxPrints: 0,
	favorites: [],
	admin: function () {
		return false;
	},
	isLogged: function () {
		return false;
	},
	logout: function () { },
	can: function (permission: keyof UserPermissions): boolean {
		return false;
	}
};

export type ContextProviderProps = {
	member: IMember;
	setMember: (member: IMember) => void;
	setHash: (hash: string | null) => void;
	setAvatar: (avatar: string | null) => void;
	setEmail: (email: string) => void;
	setFirstName: (firstName: string) => void;
	setLastName: (lastName: string | null) => void;
	setFullName: (fullName: string) => void;
	setMemberID: (memberID: EntityID) => void;
	setValid: (valid: boolean) => void;
	setExpires: (expires: number) => void;
	validateMember: () => boolean;
	logout: () => void;
	isLoginModalVisible: boolean;
	setIsLoginModalVisible: (visible: boolean) => void;
	isRegistrationModalVisible: boolean;
	setIsRegistrationModalVisible: (visible: boolean) => void;
	isFetchingData: boolean;
	setIsFetchingData: (state: boolean) => void;
	createMemberFromAPIResponse: (response: APIResponse) => boolean;
	lookForCookies: () => void;
	createMember: (props: MemberProps) => IMember,
	refetchMember: (props: RefetchMemberProps) => void,
}

type RefetchMemberProps = {
	onSuccess?: () => void;
	onError?: () => void;
}

export const MemberContext = createContext<ContextProviderProps>({} as ContextProviderProps);

const MemberProvider: React.FC<IMemberProvider> = ({ children }) => {
	const __COOKIE_ID__MEMBER_HASH__: string = getSecurityCookieID();
	const [isLoginModalVisible, setIsLoginModalVisible] = useState<boolean>(false);
	const [isRegistrationModalVisible, setIsRegistrationModalVisible] = useState<boolean>(false);
	const [isFetchingData, setIsFetchingData] = useState<boolean>(true);

	const [id, setID] = useState<EntityID>(null);
	const [hash, setHash] = useState<string | null>(null);
	const [avatar, setAvatar] = useState<string | null>(null);
	const [isAdmin, setIsAdmin] = useState<boolean>(false);
	const [role, setRole] = useState<UserRoles | null>(null);
	const [email, setEmail] = useState<string>('');
	const [firstName, setFirstName] = useState<string>('');
	const [lastName, setLastName] = useState<string | null>(null);
	const [fullName, setFullName] = useState<string>('');
	const [memberID, setMemberID] = useState<EntityID>(null);
	const [companyID, setCompanyID] = useState<EntityID>(null);
	const [marketID, setMarketID] = useState<EntityID>(null);
	const [valid, setValid] = useState<boolean>(false);
	const [expires, setExpires] = useState<number>(0);
	const [finishOnboarding, setFinishOnboarding] = useState<boolean>(false);
	const [remaining, setRemaining] = useState<number>(0);
	const [maxPrints, setMaxPrints] = useState<number>(0);
	const [favorites, setFavorites] = useState<Array<EntityID>>([]);

	const [permissions, setPermissions] = useState<UserPermissions>(defaultPermissions);

	// Api
	const api = new Api('login', 's');

	// Router
	const router = useRouter();

	// Cookies
	const CookieManager = useCookies();

	const refetchMember = async ({ onSuccess, onError }: RefetchMemberProps = {}) => {
		const userAgent = navigator.userAgent;
		const isNotCompatible = userAgent.includes('Firefox');

		if (isNotCompatible && onError) {
			setIsFetchingData(false);
			return onError();
		}

		// Verifica se o cookie existe e possui um hash salvo
		const hash = CookieManager.getCookie(__COOKIE_ID__MEMBER_HASH__);
		if (hash) {
			// Exibe o modal de aguarde.
			setIsFetchingData(true);

			const response = await api.post({ hash });
			if (response.success && createMemberFromAPIResponse(response as APIResponse)) {
				if (onSuccess) onSuccess();
			}
			else if (onError) onError();
		}
		setIsFetchingData(false);
	}

	const lookForCookies = async () => {
		if (!member.isLogged()) {
			refetchMember({
				onSuccess: () => Notify.success('Bem vindo de volta!').center(),
				onError: () => Notify.Warn("Sua sessão expirou.")
			});
		}
	}

	useEffect(() => {
		lookForCookies();
	}, []);

	const saveMemberCookies = (member: IMember) => {
		// Converte o timestamp 'member.expires' em dias
		const now = new Date();
		const expiresDate = new Date(member.expires * 1000);
		const diffInMilliseconds = expiresDate.getTime() - now.getTime();
		const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 3600 * 24));

		if (member.isLogged()) CookieManager.setCookie(__COOKIE_ID__MEMBER_HASH__, member.hash as string, { expires: diffInDays });
		else CookieManager.removeCookie(__COOKIE_ID__MEMBER_HASH__);
	};

	const setMember = (newMember: IMember) => {
		setID(newMember.id);
		setHash(newMember.hash);
		setAvatar(newMember.avatar);
		setIsAdmin(newMember.isAdmin);
		setEmail(newMember.email);
		setFirstName(newMember.firstName);
		setLastName(newMember.lastName);
		setFullName(newMember.fullName);
		setMemberID(newMember.memberID);
		setCompanyID(newMember.companyID);
		setRole(newMember.role);
		setFinishOnboarding(newMember.finishOnboarding);
		setMarketID(newMember.marketID);
		setValid(newMember.valid);
		setExpires(newMember.expires);
		setRemaining(newMember.remaining);
		setMaxPrints(newMember.maxPrints);
		setFavorites(newMember.favorites);
		saveMemberCookies(newMember);
	}

	const validateMember = () => {
		const currentTimestamp = Math.floor(Date.now() / 1000);
		return member.valid && member.expires > currentTimestamp;
	};

	const logout = () => {
		router.replace('/');
		setMember(defaultMember);
		setPermissions(defaultPermissions);
	}

	const checkPermission = (permission: keyof UserPermissions) => {
		return permissions[permission];
	}

	const createMember = ({
		id,
		hash,
		email,
		memberID,
		companyID,
		marketID,
		valid,
		expires,
		firstName,
		lastName,
		fullName,
		avatar,
		isAdmin,
		role,
		finishOnboarding,
		remaining,
		maxPrints,
		favorites,
	}: MemberProps): IMember => {
		return {
			id,
			hash,
			avatar,
			email,
			firstName,
			lastName,
			fullName,
			memberID,
			companyID,
			marketID,
			isAdmin,
			role,
			finishOnboarding,
			valid,
			expires,
			remaining,
			maxPrints,
			favorites,
			admin: () => isAdmin === true,
			isLogged: function () {
				const currentTimestamp = Math.floor(Date.now() / 1000);
				return valid && expires > currentTimestamp;
			},
			can: checkPermission,
			logout: logout
		};
	}

	const member: IMember = createMember({
		id,
		hash,
		email,
		memberID,
		companyID,
		marketID,
		valid,
		expires,
		firstName,
		lastName,
		fullName,
		avatar,
		isAdmin,
		role,
		finishOnboarding,
		remaining,
		maxPrints,
		favorites,
	});

	const createMemberFromAPIResponse = (response: APIResponse): boolean => {
		if (response.data && "auth" in response.data) {
			const { data } = response;
			const { auth } = data;
			const memberData = auth.Member;
			const memberPermissions = memberData.Permissions.allows_to;

			if (Boolean(auth.Valid)) {
				const newMember = createMember({
					id: memberData.ID,
					hash: auth.Hash,
					email: memberData.Email,
					memberID: auth.MemberID,
					companyID: auth.CompanyID,
					marketID: memberData.Market.StoreID,
					valid: true,
					expires: auth.Expires,
					firstName: memberData.FirstName,
					lastName: memberData.LastName,
					fullName: memberData.FullName,
					avatar: memberData.Avatar,
					isAdmin: memberData.IsAdmin,
					role: memberData.Role,
					finishOnboarding: memberData.FinishOnboarding,
					remaining: memberData.Remaining,
					maxPrints: memberData.MaxPrints,
					favorites: memberData.Favorites,
				});
				setMember(newMember);
				setPermissions({
					create_banner: Boolean(memberPermissions.create_banner),
					send_to_queue: Boolean(memberPermissions.send_to_queue),
					print_queue: Boolean(memberPermissions.print_queue),
					send_to_any_queue: Boolean(memberPermissions.send_to_any_queue),
					manage_company: Boolean(memberPermissions.manage_company),
					manage_products: Boolean(memberPermissions.manage_products),
					manage_public_assets: Boolean(memberPermissions.manage_public_assets),
					upload_fonts: Boolean(memberPermissions.upload_fonts),
					upload_images: Boolean(memberPermissions.upload_images),
					manage_island: Boolean(memberPermissions.manage_island),
					generate_video: Boolean(memberPermissions.generate_video),
					edit_banner_texts: Boolean(memberPermissions.edit_banner_texts),
					show_open_banner_button: Boolean(memberPermissions.show_open_banner_button),
					show_packFilter_print_page: Boolean(memberPermissions.show_packFilter_print_page),
				});
				return true;
			}
		}
		return false;
	}

	return (
		<MemberContext.Provider
			value={{
				member,
				setMember,
				logout,
				setHash,
				setAvatar,
				setEmail,
				setFirstName,
				setLastName,
				setFullName,
				setMemberID,
				setValid,
				setExpires,
				validateMember,
				isLoginModalVisible,
				setIsLoginModalVisible,
				isRegistrationModalVisible,
				setIsRegistrationModalVisible,
				isFetchingData,
				setIsFetchingData,
				createMemberFromAPIResponse,
				lookForCookies,
				createMember,
				refetchMember,
			}}
		>
			{children}
		</MemberContext.Provider>
	);
};

export default MemberProvider;

export function useMember() {
	const context = useContext(MemberContext);
	if (!context) {
		throw new Error('useMember must be used within a MemberProvider');
	}
	return context;
}

function getSecurityCookieID(): string {
	if (!process.env.NEXT_PUBLIC_COOKIE_ID__MEMBER_SECURITY_HASH) {
		throw new Error("No security id provided");
	}
	return process.env.NEXT_PUBLIC_COOKIE_ID__MEMBER_SECURITY_HASH;
}
