import { EntityID } from "@/types/silverstripe";
import { PriceType } from "../canvas";

export type AllowedPackType = "un" | "kg" | "fd" | "cx";

export type ExternalSearchType = "general" | "description" | "ean" | "code";

export type PackType = 'UN' | 'FD' | 'CX' | 'PCT' | 'KG' | 'LT' | 'GR' | "CPF" | "QTD";

export type SilverProduct = {
	ID: EntityID,
	Ean: string | null,
	Code: string | null,
	Description: string,
	ShortDescription: string,
	DescriptionConnect: string,
	IdFamily: EntityID,
	SPrice: string | null,
	CPrice: string | null,
	OPrice: string | null,
	EPrice: string | null,
	PackType: PackType,
	PackQuantity: string | number,
	ImageURL: string | null,
	MarketID: EntityID,
	ExtraParameter_1: string | null,
	ExtraParameter_2: string | null,
	ExtraParameter_3: string | null,
	ExtraParameter_4: string | null,
	ExtraParameter_5: string | null,
	ExtraParameter_6: string | null,
	ExtraParameter_7: string | null,
	ExtraParameter_8: string | null,
	ExtraParameter_9: string | null,
	ExtraParameter_10: string | null,
	InitDate: Date;
	EndDate: Date;
	cpfLimit?: number | null;
	dueDate?: boolean;
	PriceType?: PriceType | 'none';
	wantUseProductPerGram?: boolean;
	limitation?: PackType;
	limitationQuantity: number;
	OfertaApp: string | null;
}

export type BulkSilverProduct = SilverProduct & {
	BulkID: string;
}

export type ICSVCustomFields = { name: keyof ProductListItem, param: string }

export type ProductListItem = {
	id: EntityID;
	ean: string | null;
	innerCode: string | null;
	description: string;
	shortDescription?: string | null;
	descriptionConnect?: string | null;
	idFamily?: EntityID;
	wantShortDescription?: boolean;
	wantConnectDescription?: boolean;
	wantUseProductPerGram?: boolean;
	packType: PackType;
	packQuantity: string | number;
	sPrice: string | null;
	cPrice: string | null;
	oPrice: string | null;
	ePrice: string | null;
	preview: string | null;
	extraParameter_1: string | null;
	extraParameter_2: string | null;
	extraParameter_3: string | null;
	extraParameter_4: string | null;
	extraParameter_5: string | null;
	extraParameter_6: string | null;
	extraParameter_7: string | null;
	extraParameter_8: string | null;
	extraParameter_9: string | null;
	extraParameter_10: string | null;
	initDate: Date | null;
	endDate: Date | null;
	cpfLimit?: number | null;
	priceType?: PriceType | 'none';
	marketID?: EntityID;

	dueDate?: boolean;
	gram?: boolean;

	limitation?: PackType | null;
	limitationQuantity?: number | 0;

	calculate?: boolean;
	dynamicPricingDigits?: string | null;
	dynamicPricingCents?: string | null;
	ofertaApp?: string | null
}

export type ProductsQuantity = {
	ean: EntityID,
	_2A3: number,
	A3: number,
	_2A4: number,
	A4: number,
	A5: number,
	A6: number,
	A7: number,
	p30: number,
	p21: number
}

export type IPaperQuantityAutomation = {
	_2A3: number,
	A3: number,
	_2A4: number,
	A4: number,
	A5: number,
	A6: number,
	A7: number
}

export type ProductsQuantityAutomation = {
	ean: EntityID,
	vertical: IPaperQuantityAutomation,
	horizontal: IPaperQuantityAutomation
	p21: IPaperQuantityAutomation,
	p30: IPaperQuantityAutomation,
}

export type ProductBulkListItem = ProductListItem & {
	bulkID: string;
}

export type ProductListItemInfo = {
	id: EntityID;
	ean: string | null;
	innerCode: string | null;
	description: string;
	sPrice: string | null;
	cPrice: string | null;
	oPrice: string | null;
	ePrice: string | null;
}

export type BannerProducts = {
	id: string;
	item: ProductListItem;
}

export const linkField = [
	"ExtraParameter_1",
	"ExtraParameter_2",
	"ExtraParameter_3",
	"ExtraParameter_4",
	"ExtraParameter_5",
	"ExtraParameter_6",
	"ExtraParameter_7",
	"ExtraParameter_8",
	"ExtraParameter_9",
	"ExtraParameter_10",
];

export type FieldOption = { value: keyof ProductListItem, label: string };

export const __FIELD_OPTIONS__: Array<FieldOption> = [
	{ value: "ean", label: "EAN" },
	{ value: "innerCode", label: "Código Interno" },
	{ value: "packType", label: "Tipo de Embalagem" },
	{ value: "packQuantity", label: "Quantidade de Embalagem" },
	{ value: "initDate", label: "Data de Inicio" },
	{ value: "endDate", label: "Data Limite" },
	{ value: "cpfLimit", label: "Limite por CPF" },
];