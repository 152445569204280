import { PaperSize } from "@/contexts/pdf/interfaces";
import { now } from "@/helpers";
import ImageHelper from "@/helpers/ImageHelper";
import Notify from "@/helpers/Notify";
import { Orientation } from "@/types/canvas";
import { APIImageQueue, QueueData } from "@/types/queue";
import jsPDF from "jspdf";

interface CreatePDFInterface {
	paper: PaperSize,
	orientation: Orientation,
	imageQueue: APIImageQueue,
	singlePage: boolean,
	papersNotAvailable: PaperSize[]
}

interface LoadedImageData {
	image: HTMLImageElement | string;
	imageData: QueueData;
	quantity: number;
	x: number;
	y: number;
	index: number;
}

class PDF {
	static isFormat(target: Orientation | Orientation[], format: Orientation): boolean {
		return typeof target === 'string' ? target === format
			: target.includes(format);
	}

	static async create({ paper, orientation, imageQueue, singlePage, papersNotAvailable }: CreatePDFInterface) {

		const dimensions = PDF.getDimensions(paper, orientation, singlePage)
		if (papersNotAvailable.includes(paper)) return;
		if (!dimensions) return;

		const { width, height, orientation: jsPDFOrientation } = dimensions;

		let spacing = 0;
		let marginX = 0;
		let marginY = 0;

		const a4Width = 210;
		const a4Height = 297;

		const isVertical = orientation === "vertical";
		const isHorizontal = orientation === "horizontal";

		const isA4H = isHorizontal && paper === "A4";

		const is2A4 = isVertical && paper === "_2A4";
		const is2A4H = isHorizontal && paper === "_2A4";

		const is2A3 = isVertical && paper === "_2A3";
		const is2A3H = isHorizontal && paper === "_2A3";

		const isA3 = paper === "A3";
		const isA3H = isHorizontal && isA3;
		const isP21 = orientation === "p21";
		const isP30 = orientation === "p30";

		const isA5V = isVertical && paper === "A5";
		const isA5H = isHorizontal && paper === "A5";

		const isA7V = isVertical && paper === "A7";
		const isA7H = isHorizontal && paper === "A7";

		let a4Cols = 1;
		let a4Rows = 1;

		isA3H || isA4H ? singlePage = true : null;

		if (!singlePage && !isA3H) {
			a4Cols = isVertical ? Math.floor(a4Width / width) : Math.floor(a4Height / width);
			a4Rows = isVertical ? Math.floor(a4Height / height) : Math.floor(a4Width / height);

			if (isA5V || isA7V) a4Cols *= 2;
			if (isA5H || isA7H) a4Rows *= 2;

			if (isP21) {
				a4Cols = 1; // Apenas uma coluna para P21
				a4Rows = 4; // Quatro linhas para P21

				// Calcula o espaçamento total disponível
				const totalSpacing = 297 - (a4Rows * height); // Altura total de A4 menos a altura total dos 4 itens P21

				// Divide o espaçamento igualmente entre os 4 itens
				spacing = totalSpacing / a4Rows;
				marginX = 0;
				marginY = spacing * a4Rows;
			}

			if (isP30) {
				a4Cols = 1;
				a4Rows = 2;
				const totalSpacing = 297 - (a4Rows * height);

				spacing = totalSpacing / a4Rows;
				marginY = 2;
			}
		}

		// Garante que não será 0.
		a4Rows = Math.max(a4Rows, 1);
		a4Cols = Math.max(a4Cols, 1);

		const imagesPerPage = isA3 ? 1 : a4Cols * a4Rows;
		const originalPaperSize = ["A3", "_2A3", "_2A4"].includes(paper);

		const doc: jsPDF = new jsPDF({
			orientation: jsPDFOrientation as "p" | "l",
			unit: "mm",
			format: originalPaperSize || singlePage ? [width, height] : [a4Width, a4Height],
			compress: true
		});

		// Aux variable.
		let __HAS_ERROR__ = false;

		const insertPage = (loadedImage: LoadedImageData): void => {
			try {
				const { image, x, y } = loadedImage;

				if (isA3 && isVertical) {
					doc.addImage(image, "PNG", x, y, adjustedWidth, adjustedHeight, undefined, "FAST");
				} else if (is2A3H) {
					doc.addImage(image, "PNG", x, y, (adjustedWidth * 2), adjustedHeight, undefined, "FAST");
					doc.addPage();
					doc.addImage(image, 'PNG', -adjustedWidth, y, (adjustedWidth * 2), adjustedHeight, undefined, "FAST");
				} else if (is2A3) {
					const a2Height = a4Height * 2;
					// Cada folha vai ser um A3, mas a imagem delas vai se esticar para virar uma A2.
					doc.addImage(image, "PNG", x, y, width, a2Height, undefined, "FAST");
					doc.addPage();
					doc.addImage(image, 'PNG', x, - a4Height, width, a2Height, undefined, "FAST");
				} else if (is2A4) {
					doc.addImage(image, "PNG", x, y, adjustedWidth, 420, undefined, "FAST");
					doc.addPage();
					doc.addImage(image, 'PNG', x, -a4Width, adjustedWidth, 420, undefined, "FAST");
				} else if (is2A4H) {
					doc.addImage(image, "PNG", x, y, (width * 2), height, undefined, "FAST");
					doc.addPage();
					doc.addImage(image, 'PNG', -width, y, (width * 2), height, undefined, "FAST");
				} else if (isP21) {
					doc.addImage(image, "PNG", x, y + spacing, adjustedWidth, adjustedHeight, undefined, "FAST");
				} else if (isP30) {
					doc.addImage(image, "PNG", x, y, adjustedWidth, adjustedHeight, undefined, "FAST");
				} else {
					doc.addImage(image, "PNG", x, y, width, height, undefined, "FAST");
				}
			}
			catch (error) {
				__HAS_ERROR__ = true;
				console.error('error', error);
			}
		}

		// Ajusta a largura e a altura das imagens com base na margem
		const adjustedWidth = (isA5H) ? width : (width - (marginX / a4Cols));
		const adjustedHeight = (isA5V) ? height : (height - (marginY / a4Rows));

		const loadImageAndInsertIntoPage = async () => {
			let imageIndex = 0;
			let loadedImages: LoadedImageData[] = [];

			for (let i = 0; i < imageQueue.length; i++) {
				const imageData = imageQueue[i];
				const sizeQuantity = !(orientation.includes("p21") || orientation.includes("p30")) ?
					imageData[paper] : imageData['A4'];

				for (let quantity = 0; quantity < sizeQuantity; quantity++) {
					const col = imageIndex % a4Cols;
					const row = Math.floor((imageIndex % imagesPerPage) / a4Cols);

					const x = col * (width + marginX / a4Cols);
					const y = row * (height + marginY / a4Rows);

					const sourceUrl = ImageHelper.createServerURL(imageData.uri) as string;

					const image = new Image();
					image.src = `${sourceUrl}?t=${now()}`;

					loadedImages.push({
						image,
						imageData,
						quantity,
						x,
						y,
						index: imageIndex
					});

					if (imageIndex % imagesPerPage === 0 && imageIndex > 0) {
						doc.addPage();
					}

					insertPage(loadedImages[imageIndex]);

					imageIndex++;
				}
			}
		}

		await loadImageAndInsertIntoPage();

		if (__HAS_ERROR__) {
			Notify.Error("Ocorreram erros ao adicionar imagens ao PDF. Notifique o suporte para mais detalhes.");
			return;
		}

		const pdfBytes = doc.output('arraybuffer');
		const blob = new Blob([pdfBytes], { type: 'application/pdf' });

		const formData = new FormData();
		formData.append("_b", blob);

		return formData;
	}

	static getDimensions(paper: PaperSize, orientation: Orientation, singlePage: boolean) {
		type AVAILABLE_SIZES = "A3V" | "A4V" | "A5V" | "A6V" | "A7V" | "A1V" | "A3H" | "A4H" | "A5H" | "A6H" | "A7H" | "A1H";
		// Calcula as dimensões da folha A4 em milímetros
		const a4Width = 210.5;
		const a4Height = 297.2;

		const a1Width = 594;
		const a1Height = 841;

		const a3Width = 420;

		const a5Width = 148.5;
		const a5Height = 210;

		const a6Width = 148.5;
		const a6Height = 105;

		const a7Width = 74.2;
		const a7Height = 105;

		const p21Width = 210;
		const p21Height = 70;

		// Calcula as dimensões e orientação das páginas internas.
		const sizes = {
			A3V: { width: a4Height, height: a3Width, orientation: singlePage ? 'p' : 'l' },
			A3H: { width: a3Width, height: a4Height, orientation: 'l' },

			A5V: { width: a5Width, height: a5Height, orientation: singlePage ? 'p' : 'l' },
			A5H: { width: a5Height, height: a5Width, orientation: singlePage ? 'l' : 'p' },

			A6V: { width: a6Height, height: a6Width, orientation: 'p' },
			A6H: { width: a6Width, height: a6Height, orientation: 'l' },

			A7V: { width: a7Width, height: a7Height, orientation: singlePage ? 'p' : 'l' },
			A7H: { width: a7Height, height: a7Width, orientation: singlePage ? 'l' : 'p' },

			A4V: { width: a4Width, height: a4Height, orientation: 'p' },
			A4H: { width: a4Height, height: a4Width, orientation: 'l' },

			A1H: { width: a1Height, height: a1Width, orientation: 'l' },
			A1V: { width: a1Width, height: a1Height, orientation: 'p' },

			p21: { width: p21Width, height: p21Height, orientation: 'p' },
			p30: { width: a4Height, height: a4Width / 2, orientation: 'l' }
		}

		if (orientation === "vertical" && paper === "_2A3") return sizes.A3H;
		if (orientation === "horizontal" && paper === "_2A3") return sizes.A3V;

		if (orientation === "vertical" && paper === "_2A4") return sizes.A4H;
		if (orientation === "horizontal" && paper === "_2A4") return sizes.A4V;

		if (orientation === 'p21') return sizes.p21;
		if (orientation === "p30") return sizes.p30;

		const size = paper + (orientation === 'vertical' ? 'V' : 'H') as AVAILABLE_SIZES;
		return sizes[size] || sizes.A4V;
	}
}

export default PDF;