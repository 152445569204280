import React, { useEffect, useState } from "react";
import { useMember } from "@/contexts/member/MemberContext";
import Select from "react-select";
import Api from "@/api/Api";
import useBoolean from "@/hooks/useBoolean";
import { useCanvasDispatch } from "@/contexts/editor/CanvasContext";
import { useHistoric } from "@/contexts/historic/HistoricContext";

interface ListCampaignsProps {
	campaignName: string;
	handleCampaignNameChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

type LayoutOption = { label: string; value: string };

const ListCampaigns: React.FC<ListCampaignsProps> = ({ campaignName, handleCampaignNameChange }) => {
	const { member } = useMember();
	const { stateDispatch } = useCanvasDispatch();
	const { setCampaigns } = useHistoric();

	const [selectedLayout, setSelectedLayout] = useState<any>(null);
	const [layoutOptions, setLayoutOptions] = useState<LayoutOption[]>([]);

	const { value, on, off } = useBoolean(true);

	const handleLayoutChange = (selectedOption: any) => {
		if (setCampaigns) setCampaigns(selectedOption);

		setSelectedLayout(selectedOption);
		stateDispatch({ type: "setCampaignId", campaignId: selectedOption.value });
		stateDispatch({ type: "setCampaignName", campaignName: selectedOption.label });
		stateDispatch({ type: "setCampaignComboType", campaignComboType: selectedOption.combo });
	};

	useEffect(() => {
		if (member.isLogged()) {
			(async () => {
				const api = new Api('campaigns');
				const request = api.useHash(member);
				on();
				const response = await api.post(request, 'lc');
				if (response.success) {
					const { data } = response;
					const options = data.map((campaign: any) => ({
						label: campaign.Name,
						value: campaign.ID,
						combo: campaign.ComboType
					}));
					setLayoutOptions(options);
					off();
				}
			})();
		}
	}, [member]);

	return (
		<div className="w-full">
			<label htmlFor="searchCampaign" className="text-sm font-medium">Campanha</label>
			<div className="flex space-x-1 w-full justify-between items-center p-1">
				<Select
					id="searchCampaign"
					isLoading={value}
					loadingMessage={() => 'Carregando campanhas...'}
					options={layoutOptions}
					value={selectedLayout}
					onChange={handleLayoutChange}
					placeholder="Selecione uma Campanha existente"
					className="w-full"
				/>
			</div>
		</div>
	);
};

export default ListCampaigns;
