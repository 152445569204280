import { ProductListItem, SilverProduct } from "@/types/products";
import PriceHelper from "./PriceHelper";
import Utils from "./Utils";

export default class ProductHelper {
	static parseProduct(item: SilverProduct): ProductListItem {
		return {
			id: item.ID,
			ean: String(item.Ean),
			innerCode: item.Code,
			description: item.Description,
			shortDescription: item.ShortDescription,
			descriptionConnect: item.DescriptionConnect,
			idFamily: Utils.isConsideredEmpty(item.IdFamily) // @ts-ignore
						? 0 : parseInt(String(item.IdFamily)),
			packType: item.PackType,
			packQuantity: item.PackQuantity,
			preview: item.ImageURL,
			sPrice: PriceHelper.isPriceValid(item.SPrice) ? item.SPrice : '-',
			cPrice: PriceHelper.isPriceValid(item.CPrice) ? item.CPrice : '-',
			oPrice: PriceHelper.isPriceValid(item.OPrice) ? item.OPrice : '-',
			ePrice: PriceHelper.isPriceValid(item.EPrice) ? item.EPrice : '-',
			extraParameter_1: item.ExtraParameter_1,
			extraParameter_2: item.ExtraParameter_2,
			extraParameter_3: item.ExtraParameter_3,
			extraParameter_4: item.ExtraParameter_4,
			extraParameter_5: item.ExtraParameter_5,
			extraParameter_6: item.ExtraParameter_6,
			extraParameter_7: item.ExtraParameter_7,
			extraParameter_8: item.ExtraParameter_8,
			extraParameter_9: item.ExtraParameter_9,
			extraParameter_10: item.ExtraParameter_10,
			initDate: item.InitDate,
			endDate: item.EndDate,
			marketID: item.MarketID,
			limitationQuantity: item.limitationQuantity

		}
	}

	static uniqueFamiliesProducts(products: ProductListItem[], usePackType: boolean = true): ProductListItem[] {
		const uniqueFamilies: ProductListItem[] = [];
      
      const hasOfertaApp = products.some(p => p.ofertaApp == 't');
      const newProducts = hasOfertaApp ? products.filter(p => p.ofertaApp == 't') : products
      
		// Filtra os produtos e organiza em arrays
		newProducts.forEach(product => {
			if (product.idFamily === 0) {
				// adiciona o produto sem família
				uniqueFamilies.push(product);
			} else {
				// Conta quantas vezes o idFamily aparece            

				const count = products.filter(p => p.idFamily === product.idFamily).length;
            
				if (count === 1) {
					// adiciona o produto com família de item único
					uniqueFamilies.push(product);

				} else {

					// Verifica se já não tem na lista um item da família.
					// O uso do filtro packType depende do parâmetro usePackType.
					const alreadyExists = usePackType
						? uniqueFamilies.some(rf => rf.idFamily === product.idFamily && rf.packType === product.packType)
						: uniqueFamilies.some(rf => rf.idFamily === product.idFamily);

					if (!alreadyExists) {
						if (product.shortDescription) product.description = product.shortDescription;
						if (product.descriptionConnect) product.description = product.descriptionConnect;
						uniqueFamilies.push(product);
					}
				}
			}
		});

      console.log('uniqueFamilies: ', uniqueFamilies);

		return uniqueFamilies.filter((product, index, self) => {
			// Verifica se o ean já apareceu antes no array
			return index === self.findIndex(p => p.ean === product.ean);
		});
	};
	static exempleWSProducts() {
      return exempleWSProducts;
   }
}

const exempleWSProducts = {
   "code":"200",
   "success":true,
   "data":[
      {
         "Ean":"7896011945996",
         "Code":"1593",
         "MarketID":"1",
         "Description":"AZEITE BLUE VILLE 250 ML EXTRA VIRGEM",
         "SPrice":"9.98",
         "OPrice":"0",
         "CPrice":"0",
         "PackType":"UN",
         "PackQuantity":"1",
         "ShortDescription":"AZEITE BLUE VILLE 250 ML",
         "IdFamily":"1166",
         "ImageURL":"https://imageapi.salestag.com.br:9020/assets/CovPgImgs/7896011945996.png"
      },
      {
         "Ean":"7896011945460",
         "Code":"1596",
         "MarketID":"1",
         "Description":"OLEO CANOLA BLUE VILLE900 ML UN",
         "SPrice":"6.99",
         "OPrice":"0",
         "CPrice":"0",
         "PackType":"UN",
         "PackQuantity":"1",
         "ShortDescription":"OLEO CANOLA BLUE VILLE900 ML",
         "IdFamily":"1168",
         "ImageURL":"https://imageapi.salestag.com.br:9020/assets/CovPgImgs/7896011945460.png"
      },
      {
         "Ean":"7896011945453",
         "Code":"1597",
         "MarketID":"1",
         "Description":"OLEO MILHO BLUE VILLE 900 ML UN",
         "SPrice":"5.89",
         "OPrice":"0",
         "CPrice":"0",
         "PackType":"UN",
         "PackQuantity":"1",
         "ShortDescription":"OLEO MILHO BLUE VILLE 900 ML",
         "IdFamily":"1171",
         "ImageURL":"https://imageapi.salestag.com.br:9020/assets/CovPgImgs/7896011945453.png"
      },
      {
         "Ean":"7896011945446",
         "Code":"1600",
         "MarketID":"1",
         "Description":"OLEO GIRASSOL BLUE VILLE 900 ML UN",
         "SPrice":"5.69",
         "OPrice":"0",
         "CPrice":"0",
         "PackType":"UN",
         "PackQuantity":"1",
         "ShortDescription":"OLEO GIRASSOL BLUE VILLE 900 ML",
         "IdFamily":"1173",
         "ImageURL":"https://imageapi.salestag.com.br:9020/assets/CovPgImgs/7896011945446.png"
      },
      {
         "Ean":"7896011945095",
         "Code":"1602",
         "MarketID":"1",
         "Description":"OLEO BLUE VILLE ARROZ 900 ML UN",
         "SPrice":"5.99",
         "OPrice":"0",
         "CPrice":"0",
         "PackType":"UN",
         "PackQuantity":"1",
         "ShortDescription":"OLEO BLUE VILLE ARROZ 900 ML",
         "IdFamily":"1175",
         "ImageURL":"https://imageapi.salestag.com.br:9020/assets/CovPgImgs/7896011945095.png"
      },
      {
         "Ean":"7896011946863",
         "Code":"1622",
         "MarketID":"1",
         "Description":"SUCO BLUE VILLE LIGHT 200 ML PESSEGO",
         "SPrice":"0.75",
         "OPrice":"0",
         "CPrice":"0",
         "PackType":"UN",
         "PackQuantity":"1",
         "ShortDescription":"SUCO BLUE VILLE LIGHT 200 ML",
         "IdFamily":"1186",
         "ImageURL":"https://imageapi.salestag.com.br:9020/assets/CovPgImgs/7896011946863.png"
      },
      {
         "Ean":"17896011945870",
         "Code":"1622",
         "MarketID":"1",
         "Description":"SUCO BLUE VILLE LIGHT 200 ML PESSEGO",
         "SPrice":"18.69",
         "OPrice":"0",
         "CPrice":"0",
         "PackType":"CX",
         "PackQuantity":"27",
         "ShortDescription":"SUCO BLUE VILLE LIGHT 200 ML",
         "IdFamily":"1186",
         "ImageURL":"https://imageapi.salestag.com.br:9020/assets/CovPgImgs/17896011945870.png"
      },
      {
         "Ean":"27896011946867",
         "Code":"1622",
         "MarketID":"1",
         "Description":"SUCO BLUE VILLE LIGHT 200 ML PESSEGO",
         "SPrice":"18.69",
         "OPrice":"0",
         "CPrice":"0",
         "PackType":"CX",
         "PackQuantity":"27",
         "ShortDescription":"SUCO BLUE VILLE LIGHT 200 ML",
         "IdFamily":"1186",
         "ImageURL":"https://imageapi.salestag.com.br:9020/assets/CovPgImgs/27896011946867.png"
      },
      {
         "Ean":"7896011946887",
         "Code":"1627",
         "MarketID":"1",
         "Description":"SUCO BLUE VILLE 200 ML MACA",
         "SPrice":"1.19",
         "OPrice":"0",
         "CPrice":"0",
         "PackType":"UN",
         "PackQuantity":"1",
         "ShortDescription":"SUCO BLUE VILLE 200 ML",
         "IdFamily":"1188",
         "ImageURL":"https://imageapi.salestag.com.br:9020/assets/CovPgImgs/7896011946887.png"
      },
      {
         "Ean":"27896011946881",
         "Code":"1627",
         "MarketID":"1",
         "Description":"SUCO BLUE VILLE 200 ML MACA",
         "SPrice":"29.5",
         "OPrice":"0",
         "CPrice":"0",
         "PackType":"CX",
         "PackQuantity":"27",
         "ShortDescription":"SUCO BLUE VILLE 200 ML",
         "IdFamily":"1188",
         "ImageURL":"https://imageapi.salestag.com.br:9020/assets/CovPgImgs/27896011946881.png"
      },
      {
         "Ean":"7896011900049",
         "Code":"1674",
         "MarketID":"1",
         "Description":"ARROZ PARB BLUE VILLE 5 KG PARB UN",
         "SPrice":"26.9",
         "OPrice":"23.9",
         "CPrice":"22.9",
         "PackType":"UN",
         "PackQuantity":"1",
         "InitDate":"13/03/2025",
         "EndDate":"14/03/2025",
         "ShortDescription":"ARROZ PARB BLUE VILLE 5 KG",
         "IdFamily":"1207",
         "ImageURL":"https://imageapi.salestag.com.br:9020/assets/CovPgImgs/7896011900049.png"
      },
      {
         "Ean":"7528",
         "Code":"1674",
         "MarketID":"1",
         "Description":"ARROZ PARB BLUE VILLE 5 KG PARB UN",
         "SPrice":"150",
         "OPrice":"143.4",
         "CPrice":"137.4",
         "PackType":"AP",
         "PackQuantity":"6",
         "InitDate":"13/03/2025",
         "EndDate":"14/03/2025",
         "ShortDescription":"ARROZ PARB BLUE VILLE 5 KG",
         "IdFamily":"1207",
         "ImageURL":"https://imageapi.salestag.com.br:9020/assets/CovPgImgs/7528.png"
      },
      {
         "Ean":"1000000004304",
         "Code":"1674",
         "MarketID":"1",
         "Description":"ARROZ PARB BLUE VILLE 5 KG PARB UN",
         "SPrice":"150",
         "OPrice":"143.4",
         "CPrice":"137.4",
         "PackType":"AP",
         "PackQuantity":"6",
         "InitDate":"13/03/2025",
         "EndDate":"14/03/2025",
         "ShortDescription":"ARROZ PARB BLUE VILLE 5 KG",
         "IdFamily":"1207",
         "ImageURL":"https://imageapi.salestag.com.br:9020/assets/CovPgImgs/1000000004304.png"
      },
      {
         "Ean":"17896011940165",
         "Code":"1674",
         "MarketID":"1",
         "Description":"ARROZ PARB BLUE VILLE 5 KG PARB UN",
         "SPrice":"150",
         "OPrice":"143.4",
         "CPrice":"137.4",
         "PackType":"AP",
         "PackQuantity":"6",
         "InitDate":"13/03/2025",
         "EndDate":"14/03/2025",
         "ShortDescription":"ARROZ PARB BLUE VILLE 5 KG",
         "IdFamily":"1207",
         "ImageURL":"https://imageapi.salestag.com.br:9020/assets/CovPgImgs/17896011940165.png"
      },
      {
         "Ean":"7896011906874",
         "Code":"1675",
         "MarketID":"1",
         "Description":"ARROZ BR BLUE VILLE 5 KG TP 1 UN",
         "SPrice":"26.9",
         "OPrice":"23.9",
         "CPrice":"22.9",
         "PackType":"UN",
         "PackQuantity":"1",
         "InitDate":"13/03/2025",
         "EndDate":"14/03/2025",
         "ShortDescription":"ARROZ BR BLUE VILLE 5 KG TP 1",
         "IdFamily":"1208",
         "ImageURL":"https://imageapi.salestag.com.br:9020/assets/CovPgImgs/7896011906874.png"
      },
      {
         "Ean":"7896011946825",
         "Code":"1675",
         "MarketID":"1",
         "Description":"ARROZ BR BLUE VILLE 5 KG TP 1 UN",
         "SPrice":"26.9",
         "OPrice":"23.9",
         "CPrice":"22.9",
         "PackType":"UN",
         "PackQuantity":"1",
         "InitDate":"13/03/2025",
         "EndDate":"14/03/2025",
         "ShortDescription":"ARROZ BR BLUE VILLE 5 KG TP 1",
         "IdFamily":"1208",
         "ImageURL":"https://imageapi.salestag.com.br:9020/assets/CovPgImgs/7896011946825.png"
      },
      {
         "Ean":"7474",
         "Code":"1675",
         "MarketID":"1",
         "Description":"ARROZ BR BLUE VILLE 5 KG TP 1 UN",
         "SPrice":"150",
         "OPrice":"143.4",
         "CPrice":"137.4",
         "PackType":"AP",
         "PackQuantity":"6",
         "InitDate":"13/03/2025",
         "EndDate":"14/03/2025",
         "ShortDescription":"ARROZ BR BLUE VILLE 5 KG TP 1",
         "IdFamily":"1208",
         "ImageURL":"https://imageapi.salestag.com.br:9020/assets/CovPgImgs/7474.png"
      },
      {
         "Ean":"1000000004298",
         "Code":"1675",
         "MarketID":"1",
         "Description":"ARROZ BR BLUE VILLE 5 KG TP 1 UN",
         "SPrice":"150",
         "OPrice":"143.4",
         "CPrice":"137.4",
         "PackType":"AP",
         "PackQuantity":"6",
         "InitDate":"13/03/2025",
         "EndDate":"14/03/2025",
         "ShortDescription":"ARROZ BR BLUE VILLE 5 KG TP 1",
         "IdFamily":"1208",
         "ImageURL":"https://imageapi.salestag.com.br:9020/assets/CovPgImgs/1000000004298.png"
      },
      {
         "Ean":"17896011909018",
         "Code":"1675",
         "MarketID":"1",
         "Description":"ARROZ BR BLUE VILLE 5 KG TP 1 UN",
         "SPrice":"150",
         "OPrice":"143.4",
         "CPrice":"137.4",
         "PackType":"AP",
         "PackQuantity":"6",
         "InitDate":"13/03/2025",
         "EndDate":"14/03/2025",
         "ShortDescription":"ARROZ BR BLUE VILLE 5 KG TP 1",
         "IdFamily":"1208",
         "ImageURL":"https://imageapi.salestag.com.br:9020/assets/CovPgImgs/17896011909018.png"
      },
      {
         "Ean":"7896011900025",
         "Code":"8445",
         "MarketID":"1",
         "Description":"ARROZ PARB BLUE VILLE 1 KG",
         "SPrice":"5.99",
         "OPrice":"0",
         "CPrice":"0",
         "PackType":"UN",
         "PackQuantity":"1",
         "ShortDescription":"ARROZ PARB BLUE VILLE 1 KG",
         "IdFamily":"5829",
         "ImageURL":"https://imageapi.salestag.com.br:9020/assets/CovPgImgs/7896011900025.png"
      },
      {
         "Ean":"7896021314744",
         "Code":"112234",
         "MarketID":"1",
         "Description":"MAC D SAPORE C/OVOS 500 GR PARAFUSO",
         "SPrice":"1.99",
         "OPrice":"1.99",
         "CPrice":"0",
         "PackType":"UN",
         "PackQuantity":"1",
         "InitDate":"13/03/2025",
         "EndDate":"14/03/2025",
         "ShortDescription":"MAC D SAPORE C/OVOS 500 GR PARAFUSO",
         "IdFamily":"113920",
         "ImageURL":"https://imageapi.salestag.com.br:9020/assets/CovPgImgs/7896021314744.png"
      },
      {
         "Ean":"7896545400015",
         "Code":"88705",
         "MarketID":"1",
         "Description":"FEIJAO CARIOCA KI CARIOCA 1 KG",
         "SPrice":"6.99",
         "OPrice":"6.49",
         "CPrice":"5.99",
         "PackType":"UN",
         "PackQuantity":"1",
         "InitDate":"13/03/2025",
         "EndDate":"14/03/2025",
         "ShortDescription":"FEIJAO CARIOCA KI CARIOCA 1 KG",
         "IdFamily":"66528",
         "ImageURL":"https://imageapi.salestag.com.br:9020/assets/CovPgImgs/7896545400015.png"
      },
      {
         "Ean":"7891080400087",
         "Code":"8362",
         "MarketID":"1",
         "Description":"MARG DELICIA 500 GR COM SAL",
         "SPrice":"4.75",
         "OPrice":"4.69",
         "CPrice":"4.39",
         "PackType":"UN",
         "PackQuantity":"1",
         "InitDate":"13/03/2025",
         "EndDate":"14/03/2025",
         "ShortDescription":"MARG DELICIA 500 GR",
         "IdFamily":"5797",
         "ImageURL":"https://imageapi.salestag.com.br:9020/assets/CovPgImgs/7891080400087.png"
      },
      {
         "Ean":"7898080640024",
         "Code":"80833",
         "MarketID":"1",
         "Description":"LEITE LV ITALAC 1 LT S/TAMPA DESNATADO",
         "SPrice":"4.99",
         "OPrice":"4.69",
         "CPrice":"4.65",
         "PackType":"UN",
         "PackQuantity":"1",
         "InitDate":"13/03/2025",
         "EndDate":"14/03/2025",
         "ShortDescription":"LEITE LV ITALAC 1 LT S/TAMPA",
         "IdFamily":"32361",
         "ImageURL":"https://imageapi.salestag.com.br:9020/assets/CovPgImgs/7898080640024.png"
      },
      {
         "Ean":"1533",
         "Code":"7091",
         "MarketID":"1",
         "Description":"PEITO FRANGO C/OSSO A GRANEL RESFR",
         "SPrice":"16.39",
         "OPrice":"13.49",
         "CPrice":"12.99",
         "PackType":"KG",
         "PackQuantity":"1",
         "InitDate":"13/03/2025",
         "EndDate":"14/03/2025",
         "ShortDescription":"PEITO FRANGO C/OSSO A GRANEL RESFR",
         "IdFamily":"4868",
         "ImageURL":"https://imageapi.salestag.com.br:9020/assets/CovPgImgs/1533.png"
      },
      {
         "Ean":"23214",
         "Code":"14692",
         "MarketID":"1",
         "Description":"COXA E SOBRECOXA FRANGO KG CONGELADO",
         "SPrice":"11.9",
         "OPrice":"9.9",
         "CPrice":"9.49",
         "PackType":"KG",
         "PackQuantity":"1",
         "InitDate":"13/03/2025",
         "EndDate":"14/03/2025",
         "ShortDescription":"COXA E SOBRECOXA FRANGO KG",
         "IdFamily":"10497",
         "ImageURL":"https://imageapi.salestag.com.br:9020/assets/CovPgImgs/23214.png"
      },
      {
         "Ean":"1885",
         "Code":"7925",
         "MarketID":"1",
         "Description":"PATINHO BIFE KG",
         "SPrice":"45.9",
         "OPrice":"35.9",
         "CPrice":"34.9",
         "PackType":"KG",
         "PackQuantity":"1",
         "InitDate":"13/03/2025",
         "EndDate":"14/03/2025",
         "ShortDescription":"PATINHO BIFE",
         "IdFamily":"5441",
         "ImageURL":"https://imageapi.salestag.com.br:9020/assets/CovPgImgs/1885.png"
      },
      {
         "Ean":"1953",
         "Code":"7928",
         "MarketID":"1",
         "Description":"MIOLO ACEM KG",
         "SPrice":"39.9",
         "OPrice":"31.9",
         "CPrice":"30.9",
         "PackType":"KG",
         "PackQuantity":"1",
         "InitDate":"13/03/2025",
         "EndDate":"14/03/2025",
         "ShortDescription":"MIOLO ACEM",
         "IdFamily":"5444",
         "ImageURL":"https://imageapi.salestag.com.br:9020/assets/CovPgImgs/1953.png"
      }
   ],
   "socketID":"liXIlylSpT6GEexWADoa"
}